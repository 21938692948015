import { put, takeLatest, select, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { RouteAliases } from '@optx/models/routes';
import { AppState } from '@optx/redux/interfaces';
import Company, { CompanyProfile } from '@optx/models/Company';
import { APIBoolean } from '@optx/models/api/generic';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// services
import { UserService } from '@optx/services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getRouteAlias } from '@optx/utils/routes';
// redux
import * as scoreGrowthActions from '@features/company/graphs/score/state/actions';
import * as cardSelectors from '@features/long-card/company-card/state/selectors';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as routerSelectors } from '@redux/router';
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@features/company/audit-trail/state';
import { actions as checklistResearchActions } from '@features/checklist-research-insights/state/actions';
import { getCompanyById as checklistGetCompany } from '@features/checklist-research-insights/state/selectors';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  AddResearchRationale,
  AddResearchRationaleCompany,
  FieldType,
  SaveResearchRationaleResponse,
  SubmitRationaleResponse,
  SaveResearchRationalePayload,
  SaveResearchRationalePayloadFields,
} from './interfaces';
import { getCompanyById as getSearchCompanyById } from '@features/grid/search/state/selectors';

export function* toggleAddResearchRationaleSaga(action: PayloadAction<AddResearchRationale>) {
  const companyId = action.payload?.companyId;
  const pageAlias: RouteAliases | null = getRouteAlias(companyId);
  let company: Company | CompanyProfile | null;
  const defaultScore: string = yield select(userSelectors.getDefaultScore);
  const isOpen: boolean = yield select(selectors.isOpen);

  if (!companyId) return;

  if (isOpen) {
    switch (pageAlias) {
      case 'userLists':
      case 'watchList':
      case 'sourceScrubLists':
        company = yield select(getSearchCompanyById('lists', companyId));
        break;

      case 'chromeExtension':
        company = yield select(cardSelectors.getProfile);
        break;

      case 'myCompanies':
        company = yield select(getSearchCompanyById('myCompanies', companyId));
        break;

      case 'advancedSearch':
        company = yield select(getSearchCompanyById('advancedSearch', companyId));
        break;

      case 'companyOutReach':
        company = yield select(getSearchCompanyById('outreach', companyId));
        break;

      case 'checklistResearchInsights':
        company = yield select(checklistGetCompany(companyId));
        break;

      case 'companyAddonManagement':
        company = yield select(getSearchCompanyById('addons', companyId));
        break;
      case 'companyProfile':
      default:
        company = yield select((state: AppState) => state.companyProfile.data);
        break;
    }

    yield put(actions.toggleAddResearchRationaleSuccess({ company, defaultScore }));
  }
}

export function* submitResearchRationaleSaga() {
  const defaultScore: string = yield select(userSelectors.getDefaultScore);
  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  try {
    const company: AddResearchRationaleCompany = yield select(selectors.getCompanyInfo);
    const fields: FieldType = yield select(selectors.getFields);
    const rationale: FieldType = yield select(selectors.getRationale);
    const initialFields: FieldType = yield select(selectors.getInitialFields);
    const initialRationale: FieldType = yield select(selectors.getInitialRationale);

    const fieldsPayload: SaveResearchRationalePayloadFields = {};

    if (
      fields.is_software !== initialFields.is_software ||
      rationale.is_software_rationales !== initialRationale.is_software_rationales ||
      fields.is_software === true
    ) {
      fieldsPayload.software_company = {
        value: fields.is_software,
        rationale: rationale.is_software_rationales,
      };
    }

    if (
      fields.is_interesting !== initialFields.is_interesting ||
      rationale.psg_fit_rationales !== initialRationale.psg_fit_rationales ||
      fields.is_interesting === true
    ) {
      fieldsPayload.psg_fit = {
        value: fields.is_interesting,
        rationale: rationale.psg_fit_rationales,
      };
    }

    if (
      fields.is_software &&
      fields.is_interesting &&
      (fields.optx_score_verified !== initialFields.optx_score_verified ||
        rationale.optx_score_rationales !== initialRationale.optx_score_rationales ||
        rationale.optx_score_lower_rationales !== initialRationale.optx_score_lower_rationales ||
        rationale.optx_score_upper_rationales !== initialRationale.optx_score_upper_rationales)
    ) {
      fieldsPayload.optx_score = {
        optx_score_verified: fields.optx_score_verified,
        rationale: rationale.optx_score_rationales,
        rationale_reason_lower: rationale.optx_score_lower_rationales,
        rationale_reason_upper: rationale.optx_score_upper_rationales,
      };
    }

    const payload: SaveResearchRationalePayload = {
      company_id: company.company_id,
      fields: { ...fieldsPayload },
    };

    // api requires sending "-" instead of null for optx score thumbs up
    const res: AxiosResponse<SubmitRationaleResponse> = yield call(
      UserService.sendResearchRationale,
      payload
    );

    const customPayload: SaveResearchRationaleResponse = {
      ...payload,
      defaultScore,
      scoreRationale: res.data.optx_score_rl,
      scoreValues: {
        // default score values changed by fit data
        fitScore: res.data.optx_score,
        fitScoreGrowth: res.data.optx_score_growth,
        // israel score values changed by fit data
        fitIsraelScore: res.data.il_optx_score,
        fitIsraelScoreGrowth: res.data.il_optx_score_growth,
        // default score values changed by software data
        softwareScore: res.data.optx_score,
        softwareScoreGrowth: res.data.optx_score_growth,
        // israel score values changed by software data
        softwareIsraelScore: res.data.il_optx_score,
        softwareIsraelScoreGrowth: res.data.il_optx_score_growth,
        checklistReviewedBy: res.data.checklist_reviewed_by,
        checklistReviewedDate: res.data.checklist_reviewed_date,
      },
    };
    yield put(actions.submitResearchRationaleSuccess(customPayload));

    if (window.location.pathname.includes('checklist-research-insights')) {
      yield put(checklistResearchActions.card.fetchCard());
    }

    yield put(
      scoreGrowthActions.fetchScoreGrowth({
        company_id: company.company_id,
        company_url: company?.company_url as string,
      })
    );

    // getting the current tab from Company Profile
    // @ts-ignore
    // @ts-ignore
    const tabNumber = yield select(routerSelectors.getLocation);

    if (tabNumber && tabNumber.query.tab === CompanyProfileTabs.HISTORY) {
      const { pageSize } = yield select(auditSelectors.getPagination);
      const field: string = yield select(auditSelectors.getSearchQuery);
      const optx: APIBoolean = yield select(auditSelectors.getOPTX);
      const internal: APIBoolean = yield select(auditSelectors.getInternal);
      const startDate: string = yield select(auditSelectors.getStartDate);
      const endDate: string = yield select(auditSelectors.getEndDate);

      yield put(
        auditActions.fetchChangedCompanyFields({
          companyId: company.company_id,
          pagination: {
            pageNumber: 1,
            pageSize,
          },
          field,
          internal,
          optx,
          startDate,
          endDate,
        })
      );
    }

    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
    NotificationService.success('Success to add research rationale');
  } catch (error: any) {
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
    const errorMessage = 'Failed to save research rationale!';

    NotificationService.error(errorMessage);
  }
}

export default function* addResearchRationaleSaga() {
  yield takeLatest(actions.toggleAddResearchRationale, toggleAddResearchRationaleSaga);
  yield takeLatest(actions.submitResearchRationale, submitResearchRationaleSaga);
}
