import { useEffect, useState, useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Truncate from 'react-truncate';
import { DoubleRightOutlined } from '@ant-design/icons';
// models
import { CompanyProfile } from '@optx/models/Company';
// redux
import {
  actions as relationshipManagementActions,
  selectors as relationshipManagementSelectors,
} from '@redux/company/relationship-management';
import { actions as companyProfileActions } from '@optx/redux/company/profile';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
// components
import { PoweredByAITag } from '@optx/components/common/powered-by-ai-tag';
import Indicator from './Indicator';
import { FeedbackMechanism } from '@optx/components/common/feedback-mechanism';
import { OverlayLoader } from '@optx/components/common/loader';
// styles
import { StyledCard, EllipsisExpander } from './RelationshipMGMTSummary.styles';

const { Text, Paragraph } = Typography;

const selectedLabels = [
  'Total # of touches',
  'Last Touch Date',
  'Next Schedule Touch',
  'SalesLoft Cadences',
];

interface RelationshipMGMTSummaryProps {
  company: CompanyProfile;
}

const NoContent: React.FC = () => {
  return (
    <Row gutter={[10, 10]}>
      <Col>
        <div className="relationship-summary__section-title no-content">
          There are no highlights
        </div>
        <Paragraph className="d-flex flex-column">
          <Text type="secondary">PSG has not yet reached out to this company</Text>
        </Paragraph>
      </Col>
    </Row>
  );
};

const RelationshipMGMTSummary: React.FC<RelationshipMGMTSummaryProps> = ({ company }) => {
  const dispatch = useDispatch();
  const data = useSelector(relationshipManagementSelectors.getRelationshipManagementData);
  const error = useSelector(relationshipManagementSelectors.getError);

  const [loadingDescription, setLoadingDescription] = useState(false);
  const [lines, setLines] = useState<number | false>(4);
  const [isOpen, toggle] = useToggle();
  const [isThumbsDown, setIsThumbsDown] = useState(false);

  const totalNumberOfTouches = useMemo(() => {
    return Number(data?.tabs_data.find(tab => tab.id === 0)?.value || null);
  }, [data]);

  const hasContent = !error && totalNumberOfTouches > 0;

  useEffect(() => {
    dispatch(relationshipManagementActions.fetchRelationshipManagement(company.company_id));
  }, [dispatch, company]);

  const handleThumbUp = () => {
    dispatch(companyProfileActions.aiRelationshipSummary(true));
  };

  const handleThumbDown = () => {
    setLoadingDescription(true);
    dispatch(
      companyProfileActions.aiRelationshipSummary(false, () => {
        setLoadingDescription(false);
      })
    );
  };

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isOpen) {
      setLines(4);
    } else {
      setLines(false);
    }

    toggle();
  };

  const Content = () => {
    return (
      <>
        <Row gutter={[10, 10]} className="content-row">
          {data?.tabs_data.map(tab => {
            if (!selectedLabels.includes(tab.label)) {
              return null;
            }

            return (
              <Col span={24} md={12} lg={5} key={tab.id}>
                <Indicator label={tab.label} value={tab.value} type={tab.type} />
              </Col>
            );
          })}
        </Row>
        {company?.highlights && (
          <>
            <Row align="middle">
              <Col className="relationship-summary__section-title" style={{ marginRight: '10px' }}>
                Highlights
              </Col>
              <Col>
                <PoweredByAITag />
              </Col>
              <Col>
                <FeedbackMechanism
                  value={company.is_ai_highlights_approved}
                  isGenerated={true}
                  handleThumbUp={handleThumbUp}
                  handleThumbDown={handleThumbDown}
                  setIsThumbsDown={setIsThumbsDown}
                />
              </Col>
            </Row>
            <Col
              span={24}
              className="company-source-descriptions__text"
              style={{ paddingRight: 0, paddingLeft: 0 }}
            >
              {loadingDescription ? (
                <OverlayLoader isOverlay loading />
              ) : (
                <Truncate
                  lines={lines}
                  ellipsis={
                    <EllipsisExpander type="button" className="ml-1" onClick={handleToggle}>
                      ...
                    </EllipsisExpander>
                  }
                  style={{ textDecoration: isThumbsDown ? 'line-through' : 'initial' }}
                >
                  {company.highlights}
                </Truncate>
              )}
              {isOpen && (
                <Row className="justify-content-center cursor-pointer" onClick={handleToggle}>
                  <Col className="col-sm-auto">
                    <DoubleRightOutlined
                      style={{ transform: 'rotate(-90deg)', cursor: 'pointer' }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </>
        )}
      </>
    );
  };

  return (
    <StyledCard
      title={
        <div className="d-flex">
          <div className="flex-grow-1">Relationship Management Summary</div>
        </div>
      }
      className="relationship-summary__card"
    >
      {hasContent ? <Content /> : <NoContent />}
    </StyledCard>
  );
};

export default RelationshipMGMTSummary;
