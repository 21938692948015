import styled from 'styled-components';
import Icon from '@components/common/Icon';

export const NewTabIcon = styled(Icon).attrs(props => ({
  iconName: 'chrome-new-tab',
}))`
  width: 20px;
  margin-left: 5px;
`;

const ChromeExtension = styled.div`
  width: 630px;
  height: 100vh;
  position: relative;
`;

export const ChromeExtensionSection = styled.div`
  h2.ant-typography,
  .ant-card-head-title {
    font-size: 16px;
    font-weight: 700;
  }
`;

export default {
  ChromeExtension,
};
