import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, has, pick } from 'lodash';
// constants
import { BULK_ACTIONS_CONTACT_FIELDS } from '@optx/constants/equitytouch';
import { CONTACT_SELECT_LIMIT } from '@optx/constants/contacts';
// models
import { EquityTouchFetchedField, EquityTouchContact } from '@models/equityTouch';
import { SalesloftEditData } from '@models/Salesloft';
import {
  BulkCompanyData,
  CompanyBulkData,
  ResolveSalesloftSuccess,
  UpdateCompanyAndSubmit,
  SalesloftContactsBulk,
  SelectedCompanies,
  EquityTouchBulkCompanies,
  SalesloftCompanyContacts,
  EquityTouchBulkResults,
  SelectedCompaniesGrids,
  TemporaryDataEquityTouch,
} from '@models/bulkActions';
import { CompanyUserContact } from '@models/api/contacts';
import { SalesloftContact } from '@optx/models/Salesloft';
import { CompanyProfile } from '@models/Company';
// utils
import { updateContacts } from '@optx/utils/utils';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { fetchReducer } from '@redux/feature/fetch/reducers';
import {
  actions as selectedCompaniesActions,
  interfaces as selectedCompaniesModels,
} from '@features/bulk-actions/selected-companies';
import { actions as salesloftBulkActions } from '@features/bulk-actions/salesloft';
import { getSalesloftEmails } from '@optx/utils/salesloft';
import { SelectOption } from '@optx/models/Option';
import { UserInformation } from '@optx/models/user';

import { EquityTouchState } from './interfaces';
import { actions as primaryContactsActions } from 'src/redux/company/primary-contacts';
import * as actions from './actions';

const initialState: EquityTouchState = {
  companiesById: {},
  temporaryData: {},
  allCompanyIds: [],
  formData: {},
  companyData: {},
  bulkValues: {},
  editedCompanies: [],
  loading: false,
  error: '',
  fetchedAt: '',
  queue: 0,
  results: [],
  inProgress: false,
  completed: false,
  percentageCompleted: 0,
  selectedCompaniesCount: 0,
  cancel: false,
};

const fetchFormDataSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<{
    formData: Dictionary<EquityTouchFetchedField>;
    isInitializing: boolean;
    companyId?: number;
  }>
> = (draftState, action) => {
  const { formData, isInitializing } = action.payload;
  draftState.formData = formData;

  if (isInitializing) {
    draftState.fetchedAt = new Date().toISOString();
  }
};

const initializeEquityTouchBulkSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<BulkCompanyData>
> = (draftState, action) => {
  const { companiesById, allIds, listId, listType } = action.payload;
  draftState.loading = false;
  draftState.companiesById = companiesById;
  draftState.allCompanyIds = allIds;
  draftState.selectedCompaniesCount = allIds.length;

  if (listId && listType) {
    draftState.listId = listId;
    draftState.listType = listType;
  }
};

const initializeIndividualEditingReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<Dictionary<string | number | number[] | boolean>>
> = (draftState, action) => {
  const formValues = action.payload;
  const customFormValues = { ...formValues };

  delete customFormValues.FTEs;
  draftState.allCompanyIds.forEach(id => {
    if (!draftState.editedCompanies.includes(id)) {
      draftState.companiesById[id] = { ...draftState.companiesById[id], ...customFormValues };
    }
  });
  draftState.bulkValues = formValues;
};

const fetchCompanySuccessReducer: CaseReducer<EquityTouchState, PayloadAction<CompanyProfile>> = (
  draftState,
  action
) => {
  const company = action.payload;
  const companyId = company.company_id;
  draftState.companyData = company;

  draftState.companiesById[companyId].add_all_contacts = false;

  const selectedContactsLimit = company.contacts.slice(0, CONTACT_SELECT_LIMIT);

  if (draftState.companiesById[companyId].contacts === undefined) {
    draftState.companiesById[companyId].contacts =
      company.contacts.length >= CONTACT_SELECT_LIMIT ? selectedContactsLimit : company.contacts;

    draftState.temporaryData.step2 = {
      ...draftState.temporaryData.step2,
      [companyId]: {
        ...(draftState.temporaryData.step2?.[companyId] as unknown as Dictionary<number>),
        contacts:
          company.contacts.length >= CONTACT_SELECT_LIMIT
            ? selectedContactsLimit
            : company.contacts,
      },
    };
  }

  draftState.loading = false;
};

const addBulkContactReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<{
    contacts: EquityTouchContact[];
    companyId: number;
  }>
> = (draftState, action) => {
  const { companyId, contacts } = action.payload;
  draftState.companiesById[companyId].contacts = contacts || [];
};

const updateBulkContactSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const contact = action.payload;
  const { company_id: companyId } = contact;
  const companyData = draftState.companyData as CompanyProfile;

  if (Object.keys(companyData).length) {
    const contacts = companyData.contacts;

    const primaryContactIndex = contacts.findIndex(contact => contact.primary_contact);

    const index = contacts.findIndex(contactItem => contactItem.person_id === contact.person_id);

    // update the data used for the table
    if (
      primaryContactIndex > -1 &&
      contacts[primaryContactIndex].person_id !== contact.person_id &&
      contact.primary_contact
    ) {
      contacts[primaryContactIndex].primary_contact = false;
    }

    if (index > -1) {
      contacts[index] = {
        ...contacts[index],
        ...contact,
        full_name: `${contact.first_name} ${contact.last_name}`,
        additional_phones: contact.phones || null,
      };
    }
  }

  // updated the selected contacts that will be sent to backend
  const selectedContacts = draftState.companiesById[companyId as number]
    ?.contacts as EquityTouchContact[];

  if (selectedContacts?.length) {
    const selectedContacts = draftState.companiesById[companyId as number]
      .contacts as EquityTouchContact[];
    const selectedPrimaryContactIndex = selectedContacts.findIndex(
      contact => contact.primary_contact
    );
    const index = selectedContacts.findIndex(
      contactItem => contactItem.person_id === contact.person_id
    );

    if (
      selectedPrimaryContactIndex > -1 &&
      selectedContacts[selectedPrimaryContactIndex].person_id !== contact.person_id &&
      contact.primary_contact
    ) {
      selectedContacts[selectedPrimaryContactIndex].primary_contact = false;
    }

    if (index > -1) {
      selectedContacts[index] = {
        ...selectedContacts[index],
        ...contact,
        full_name: `${contact.first_name} ${contact.last_name}`,
        additional_phones: contact.phones || null,
      };
    }
  }

  // update salesloft contacts

  const salesloftContacts = draftState.companiesById[companyId as number]
    ?.salesloft_contacts as SalesloftContact[];

  const tempSalesloftContacts =
    draftState.temporaryData.step2 && draftState.temporaryData.step2[companyId as number] // if its not add to et (bulk)
      ? ((draftState.temporaryData.step2![companyId as number] as any)
          .salesloft_contacts as SalesloftContact[])
      : salesloftContacts;

  if (tempSalesloftContacts?.length) {
    const selectedPrimaryContactIndex = tempSalesloftContacts.findIndex(
      contact => contact.primary_contact
    );

    const index = tempSalesloftContacts.findIndex(
      selectedContact => selectedContact.person_id === contact.person_id
    );

    const selectedContactsIds = tempSalesloftContacts.map(
      selectedContact => selectedContact.person_id
    );

    if (selectedPrimaryContactIndex !== -1) {
      if (
        contact.primary_contact &&
        tempSalesloftContacts[selectedPrimaryContactIndex]?.person_id !== contact?.person_id &&
        !selectedContactsIds.includes(contact?.person_id as number)
      ) {
        const newContact: SalesloftContact = {
          ...contact,
          ...getSalesloftEmails(contact.email as string),
          additional_phones: contact.phones || null,
        };

        salesloftContacts.push(newContact);
      }

      if (contact.primary_contact && selectedContactsIds.includes(contact.person_id as number)) {
        salesloftContacts[index] = {
          ...salesloftContacts[index],
          ...contact,
          ...getSalesloftEmails(contact.email as string),
          additional_phones: contact.phones || null,
        };
      }

      if (!contact.primary_contact && selectedContactsIds.includes(contact.person_id as number)) {
        salesloftContacts[index] = {
          ...salesloftContacts[index],
          ...contact,
          ...getSalesloftEmails(contact.email as string),
          additional_phones: contact.phones || null,
        };
      }

      const temporaryState = {
        ...draftState.temporaryData.step2,
        [contact.company_id as any]: {
          ...(draftState.temporaryData.step2![contact.company_id as any] as any),
          salesloft_contacts: salesloftContacts,
        },
      };

      draftState.temporaryData.step2 = temporaryState;
    }

    if (selectedPrimaryContactIndex === -1) {
      const arrayOfSalesLoftContacts = [...tempSalesloftContacts];

      const newContact: SalesloftContact = {
        ...salesloftContacts[selectedPrimaryContactIndex],
        ...contact,
        ...getSalesloftEmails(contact.email as string),
        additional_phones: contact.phones || null,
      };

      const newSalesloftContacts = (
        draftState.temporaryData.step2![contact.company_id as any] as any
      ).salesloft_contacts.map((slContact: SalesloftContact) => {
        if (slContact.person_id === newContact.person_id) {
          return newContact;
        }

        return slContact;
      });

      const temporaryState = {
        ...draftState.temporaryData.step2,
        [contact.company_id as any]: {
          ...(draftState.temporaryData.step2![contact.company_id as any] as any),
          salesloft_contacts: newSalesloftContacts,
        },
      };

      draftState.temporaryData.step2 = temporaryState;

      arrayOfSalesLoftContacts[index] = newContact;

      (draftState.companiesById[companyId as number].salesloft_contacts as SalesloftContact[]) =
        arrayOfSalesLoftContacts;
    }

    if (contact.primary_contact && selectedContactsIds.includes(contact.person_id as number)) {
      if (salesloftContacts[index].primary_contact) {
        salesloftContacts[index].primary_contact = true;
      }

      if (selectedPrimaryContactIndex !== -1) {
        salesloftContacts[selectedPrimaryContactIndex].primary_contact = false;

        const newContact: SalesloftContact = {
          ...contact,
          ...getSalesloftEmails(contact.email as string),
          additional_phones: contact.phones || null,
        };

        const newSalesloftContacts = (
          draftState.temporaryData.step2![contact.company_id as any] as any
        ).salesloft_contacts;
        newSalesloftContacts[index] = newContact;

        const temporaryState = {
          ...draftState.temporaryData.step2,
          [contact.company_id as any]: {
            ...(draftState.temporaryData.step2![contact.company_id as any] as any),
            salesloft_contacts: newSalesloftContacts,
          },
        };

        draftState.temporaryData.step2 = temporaryState;

        (draftState.companiesById[companyId as number].salesloft_contacts as SalesloftContact[]) =
          newSalesloftContacts;
      }
    }
  } else if (draftState.companiesById[companyId as number]?.salesloft_contacts) {
    const index = (
      draftState.companiesById[companyId as number].salesloft_contacts as SalesloftContact[]
    ).findIndex(selectedContact => selectedContact.person_id === contact.person_id);

    if (index !== -1) {
      (draftState.companiesById[companyId as number].salesloft_contacts as SalesloftContact[])[
        index
      ] = {
        ...contact,
        ...getSalesloftEmails(contact.email as string),
        additional_phones: contact.phones || null,
        is_edited: true,
      };
    } else {
      (draftState.companiesById[companyId as number].salesloft_contacts as SalesloftContact[]).push(
        {
          ...contact,
          ...getSalesloftEmails(contact.email as string),
          additional_phones: contact.phones || null,
        }
      );
    }
  }
};

const addToEquityTouchBulkReducer: CaseReducer<EquityTouchState, PayloadAction<CompanyBulkData>> = (
  draftState,
  action
) => {
  const { formValues, cadence } = action.payload;

  if (Object.keys(formValues).length) {
    draftState.allCompanyIds.forEach(companyId => {
      if (!draftState.editedCompanies.includes(companyId)) {
        draftState.companiesById[companyId] = {
          ...draftState.companiesById[companyId],
          ...formValues,
          cadence,
        };
      }
    });
  }
};

const addToEquityTouchBulkFailReducer: CaseReducer<EquityTouchState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.completed = true;
  draftState.error = action.payload;
};

const updateBulkCadenceCompanyReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<EquityTouchBulkCompanies>
> = (draftState, action) => {
  const companyKeys = Object.keys(action.payload);
  const companyKeysFiltered = companyKeys.find(key => key === 'Company Id');
  const companyId = action.payload[companyKeysFiltered as string];

  if (companyId) {
    draftState.companiesById[companyId as unknown as number] = {
      ...draftState.companiesById[companyId as unknown as number],
      cadence: action.payload.cadence as unknown as number,
    };
  }
};

const updateBulkCompanyReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<UpdateCompanyAndSubmit>
> = (draftState, action) => {
  const formValues = action.payload.formValues;
  const companyId = formValues.company_id as number;

  draftState.companiesById[companyId] = {
    ...draftState.companiesById[companyId],
    ...formValues,
    add_all_contacts: false,
  };

  if (!draftState.editedCompanies.includes(companyId)) {
    draftState.editedCompanies.push(companyId);
  }
};

const saveResultsReducer: CaseReducer<EquityTouchState, PayloadAction<EquityTouchBulkResults[]>> = (
  draftState,
  action
) => {
  draftState.results = [...draftState.results, ...action.payload];
};

const updateProgressReducer: CaseReducer<EquityTouchState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.inProgress = action.payload;
};

const updateCompletedReducer: CaseReducer<EquityTouchState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.completed = action.payload;
};

const updatePercentageReducer: CaseReducer<EquityTouchState, PayloadAction<number>> = (
  draftState,
  action
) => {
  const addProgress = action.payload;

  if (addProgress === 0 || addProgress === 100) {
    draftState.percentageCompleted = action.payload;
  } else {
    draftState.percentageCompleted += action.payload;
  }
};

const cancelReducer: CaseReducer<EquityTouchState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.cancel = action.payload;
};

const resetProgressReducer: CaseReducer<EquityTouchState> = draftState => {
  return initialState;
};

const selectCompanyIdsReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<selectedCompaniesModels.SelectedCompaniesPayload>
> = (draftState, action) => {
  draftState.selectedCompaniesCount = action.payload.selectedCompanies.length;
};

const resolveSalesloftSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<ResolveSalesloftSuccess>
> = (draftState, action) => {
  const { company_id: companyId, contacts } = action.payload;
  const index = draftState.results.findIndex(result => result.company_id === companyId);

  if (index !== -1) {
    if (
      draftState.results[index].salesloftContacts.length === 1 &&
      draftState.results[index].salesloftContacts[0].person_id === null
    ) {
      draftState.results[index].salesloftContacts = contacts;
    } else {
      contacts.forEach(contact => {
        const resultIndex = draftState.results[index].salesloftContacts.findIndex(
          result => result.person_id === contact.person_id
        );

        if (resultIndex !== -1) {
          draftState.results[index].salesloftContacts[resultIndex] = contact;
        }
      });
    }
  }
};

const fetchContactsSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<{ contacts: CompanyUserContact[]; companyId: number }>
> = (draftState, action) => {
  const { contacts, companyId } = action.payload;

  draftState.companiesById[companyId].contacts = contacts.map(contact =>
    pick(contact, BULK_ACTIONS_CONTACT_FIELDS)
  );

  draftState.loading = false;
};

const salesloftSelectContactsBulkReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<SalesloftContactsBulk>
> = (draftState, action) => {
  const { id, companyId, type, selectedContactData } = action.payload;

  if (type === 'select') {
    if (selectedContactData) {
      if (Array.isArray(draftState.companiesById[companyId]?.salesloft_contacts)) {
        (draftState.companiesById[companyId]?.salesloft_contacts as SalesloftContact[]).push({
          ...selectedContactData,
          ...getSalesloftEmails(selectedContactData.email as string | null),
          company_id: companyId,
        });
      } else {
        draftState.companiesById[companyId].salesloft_contacts = [
          { ...selectedContactData, company_id: companyId },
        ];
      }
    }
  } else if (type === 'deselect') {
    const removeContactIndex = (
      draftState.companiesById[companyId]?.salesloft_contacts as SalesloftContact[]
    ).findIndex(contact => contact.person_id === id);

    (draftState.companiesById[companyId]?.salesloft_contacts as SalesloftContact[]).splice(
      removeContactIndex,
      1
    );
  } else {
    draftState.companiesById[companyId].salesloft_contacts = [];
  }
};

const fetchCompaniesContactsSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<{
    contacts: SalesloftCompanyContacts[];
    selectedCompanies: SelectedCompanies[];
    gridName: SelectedCompaniesGrids;
  }>
> = (draftState, action) => {
  const { contacts } = action.payload;
  draftState.loading = false;
  contacts.forEach(contact => {
    const { company_id: id, contacts } = contact;

    if (draftState.companiesById[id]) {
      const primaryContact = contacts.find(contact => contact.primary_contact);

      if (primaryContact) {
        draftState.companiesById[id].salesloft_contacts = [
          {
            ...primaryContact,
            company_id: id,
            ...getSalesloftEmails(primaryContact.primary_email as string | null),
          },
        ];
      } else {
        draftState.companiesById[id].salesloft_contacts = [];
      }
    }
  });
};

const addContactSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const contact = action.payload;

  const companyData = draftState.companiesById[contact.company_id as number];
  const companyDataContacts = companyData?.contacts as CompanyUserContact[];

  if (companyData) {
    if (companyDataContacts && companyDataContacts.length < CONTACT_SELECT_LIMIT) {
      const mappedContacts = action.payload.primary_contact
        ? companyDataContacts.map(contact => ({ ...contact, primary_contact: false }))
        : companyDataContacts;
      mappedContacts?.push(contact as CompanyUserContact);

      companyData.contacts = mappedContacts;
    } else {
      companyDataContacts?.push(contact as CompanyUserContact);
      companyData.contacts = companyDataContacts;
    }
  }

  const tempDataContacts = draftState.temporaryData.step2?.[
    contact.company_id as number
  ] as unknown as Dictionary<CompanyUserContact[]>;
  const tempDataContactsContacts = tempDataContacts?.contacts;

  if (tempDataContactsContacts && tempDataContactsContacts.length < CONTACT_SELECT_LIMIT) {
    if (contact.primary_contact) {
      const mappedContacts = action.payload.primary_contact
        ? tempDataContactsContacts.map(contact => ({ ...contact, primary_contact: false }))
        : tempDataContactsContacts;

      mappedContacts.push(contact as CompanyUserContact);
      tempDataContacts.contacts = mappedContacts;

      (tempDataContacts.salesloft_contacts as SalesloftContact[]) = [{ ...contact }];

      if (tempDataContactsContacts) {
        (tempDataContacts as any).salesloft_contacts = mappedContacts as CompanyUserContact[];
      }
    }

    const mappedContacts = action.payload.primary_contact
      ? tempDataContactsContacts.map(contact => ({ ...contact, primary_contact: false }))
      : tempDataContactsContacts;
    mappedContacts?.push(contact as CompanyUserContact);

    tempDataContacts.contacts = mappedContacts;
  } else {
    tempDataContactsContacts?.push(contact as CompanyUserContact);
  }

  const tempDataSLContacts = draftState.temporaryData.step2?.[contact.company_id as number];

  if (
    companyData &&
    companyData.salesloft_contacts &&
    (companyData.salesloft_contacts as SalesloftContact[]).length < CONTACT_SELECT_LIMIT
  ) {
    const newContact: SalesloftContact = {
      ...contact,
      company_name: contact.company_name,
      company_website: contact.company_url,
      company_id: contact.company_id,
      primary_contact: !!contact.primary_contact,
      ...getSalesloftEmails(contact.email as string),
    };

    if (Array.isArray(companyData.salesloft_contacts)) {
      if (contact.primary_contact) {
        const companyContacts = companyData.salesloft_contacts as SalesloftContact[];

        const mappedContacts = action.payload.primary_contact
          ? companyContacts.map(contact => ({ ...contact, primary_contact: false }))
          : companyContacts;
        mappedContacts.push(newContact as CompanyUserContact);
        (companyData.salesloft_contacts as SalesloftContact[]) = [{ ...newContact }];

        if (tempDataSLContacts) {
          if (contact.primary_contact) {
            (tempDataSLContacts as any).salesloft_contacts = [{ ...newContact }];
          } else {
            (tempDataSLContacts as any).salesloft_contacts = mappedContacts as CompanyUserContact[];
          }
        }
      }
    }
  }

  if (Object.keys(draftState.companyData).length) {
    if (Array.isArray((draftState.companyData as CompanyProfile).contacts)) {
      if (contact.primary_contact) {
        const companyContacts = (draftState.companyData as CompanyProfile).contacts;
        const mappedContacts = action.payload.primary_contact
          ? companyContacts.map(contact => ({ ...contact, primary_contact: false }))
          : companyContacts;
        mappedContacts.push(contact as CompanyUserContact);

        (draftState.companyData as CompanyProfile).contacts =
          mappedContacts as CompanyUserContact[];
      } else {
        (draftState.companyData as CompanyProfile).contacts.push(contact as CompanyUserContact);
      }
    } else {
      (draftState.companyData as CompanyProfile).contacts = [contact as CompanyUserContact];
    }
  }
};

const updatePrimaryContactsReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<SalesloftEditData>
> = (draftState, action) => {
  const {
    tenure_end_date: tenureEndDate,
    tenure_start_date: tenureStartDate,
    company_id: companyId,
    person_id: personId,
  } = action.payload;

  const companyData = draftState.companyData as CompanyProfile;
  const isValidData =
    typeof personId === 'number' && tenureEndDate !== undefined && tenureStartDate !== undefined;

  if (Object.keys(companyData).length) {
    const contacts = companyData.contacts;

    if (isValidData && contacts && contacts.length) {
      const updatedContacts = updateContacts(
        contacts,
        personId as number,
        tenureEndDate as string | null,
        tenureStartDate as string | null
      );

      if (updatedContacts) {
        (draftState.companyData as CompanyProfile).contacts =
          updatedContacts as CompanyUserContact[];
      }
    }
  }

  const companyByIdData = draftState.companiesById[companyId as number];

  if (companyByIdData && personId && companyId) {
    const companyDataContacts = companyByIdData?.contacts as CompanyUserContact[];

    if (isValidData && companyDataContacts && companyDataContacts.length) {
      const updatedContacts = updateContacts(
        companyDataContacts,
        personId as number,
        tenureEndDate as string | null,
        tenureStartDate as string | null
      );

      if (updatedContacts) {
        draftState.companiesById[companyId as number].contacts = updatedContacts;
      }
    }

    const tempData = draftState.temporaryData.step2?.[companyId as number] as unknown as Dictionary<
      CompanyUserContact[]
    >;

    const tempDataContacts = tempData?.contacts;

    if (isValidData && tempData && tempDataContacts && tempDataContacts.length) {
      const updatedContacts = updateContacts(
        tempDataContacts,
        personId as number,
        tenureEndDate as string | null,
        tenureStartDate as string | null
      );

      if (updatedContacts) {
        (tempData as any).contacts = updatedContacts as CompanyUserContact[];
      }
    }

    const tempDataSLContacts = tempData?.salesloft_contacts;

    if (isValidData && tempData && tempDataSLContacts && tempDataSLContacts.length) {
      const updatedContacts = updateContacts(
        tempDataSLContacts,
        personId as number,
        tenureEndDate as string | null,
        tenureStartDate as string | null
      );

      if (updatedContacts) {
        (tempData as any).salesloft_contacts = updatedContacts as CompanyUserContact[];
      }
    }
  }
};

const selectCadenceIndividuallyReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<{ cadenceId: number | string; companyId: number }>
> = (draftState, action) => {
  const { cadenceId, companyId } = action.payload;

  if (draftState.companiesById[companyId]) {
    draftState.companiesById[companyId].cadence = cadenceId;
  }
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<EquityTouchState, PayloadAction<UserInformation>> = (
  draftState,
  action
) => {
  const persistedFilter = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.et_form_auto_save
    : undefined;

  if (persistedFilter !== null && persistedFilter !== undefined) {
    if (has(persistedFilter, 'Company Name')) {
      draftState.bulkValues = persistedFilter as Dictionary<
        string | number | boolean | number[] | string[] | null | SelectOption
      >;
    } else {
      const companiesById = (persistedFilter?.companiesById ?? {}) as EquityTouchBulkCompanies;
      const formData = (persistedFilter?.formData ?? {}) as Dictionary<EquityTouchFetchedField>;
      const firstStepData = (persistedFilter?.firstStepData ?? {}) as any;
      const listId = persistedFilter?.listId;
      const listType = persistedFilter?.listType;
      const companiesData = (persistedFilter?.companiesData ?? []) as Array<any>;
      draftState.companiesById = companiesById;
      draftState.formData = formData;
      draftState.temporaryData.step1 = firstStepData;

      if (persistedFilter?.queue) {
        draftState.queue = persistedFilter.queue as number;
      }

      if (listId && listType) {
        draftState.listId = listId.toString() as string;
        draftState.listType = listType.toString() as string;
      }

      const allCompanyIds: number[] = [];
      companiesData.forEach(item => {
        if (Number.isInteger(Number(item.company_id))) {
          allCompanyIds.push(Number(item.company_id));
        }
      });
      draftState.allCompanyIds = allCompanyIds;

      draftState.selectedCompaniesCount = allCompanyIds.length;

      draftState.loading = false;
    }
  }
};

const updateTemporaryDataReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<TemporaryDataEquityTouch>
> = (draftState, action) => {
  if (action.payload?.step1) {
    draftState.temporaryData.step1 = action.payload?.step1;
  }

  if (action.payload?.step2) {
    draftState.temporaryData.step2 = action.payload?.step2;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.initializeEquityTouchBulk, fetchReducer)
    .addCase(actions.initializeEquityTouchBulkSuccess, initializeEquityTouchBulkSuccessReducer)
    .addCase(actions.initializeIndividualEditing, initializeIndividualEditingReducer)
    .addCase(actions.addToEquityTouchBulk, addToEquityTouchBulkReducer)
    .addCase(actions.addToEquityTouchBulkFail, addToEquityTouchBulkFailReducer)
    .addCase(actions.fetchFormDataSuccess, fetchFormDataSuccessReducer)
    .addCase(actions.fetchCompany, fetchReducer)
    .addCase(actions.fetchCompanySuccess, fetchCompanySuccessReducer)
    .addCase(actions.addBulkContacts, addBulkContactReducer)
    .addCase(actions.updateBulkContactSuccess, updateBulkContactSuccessReducer)
    .addCase(actions.updateBulkCompany, updateBulkCompanyReducer)
    .addCase(actions.updateBulkCadence, updateBulkCadenceCompanyReducer)
    .addCase(actions.saveResults, saveResultsReducer)
    .addCase(actions.updateProgress, updateProgressReducer)
    .addCase(actions.updateCompleted, updateCompletedReducer)
    .addCase(actions.updatePercentage, updatePercentageReducer)
    .addCase(actions.cancel, cancelReducer)
    .addCase(actions.resetProgress, resetProgressReducer)
    .addCase(actions.resolveSalesloftSuccess, resolveSalesloftSuccessReducer)
    .addCase(actions.fetchContactsSuccess, fetchContactsSuccessReducer)
    .addCase(actions.salesloftSelectContactsBulk, salesloftSelectContactsBulkReducer)
    .addCase(actions.selectCadenceIndividually, selectCadenceIndividuallyReducer)
    .addCase(actions.updateTemporaryData, updateTemporaryDataReducer)
    .addCase(salesloftBulkActions.addContactSuccess, addContactSuccessReducer)
    .addCase(selectedCompaniesActions.selectCompanyIds, selectCompanyIdsReducer)
    .addCase(
      salesloftBulkActions.fetchCompaniesContactsSuccess,
      fetchCompaniesContactsSuccessReducer
    )
    .addCase(primaryContactsActions.updatePrimaryContacts, updatePrimaryContactsReducer)
    .addCase(userInformationActions.fetchUserInformation, fetchReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
);

export default reducer;
