import { CombineSearchRequest } from '@optx/models/combine-search';
import { SearchSaveResponse } from '@optx/models/search';

/**
 * Creates select options for CombineSearchMenuItem component.
 * @param savedSearches - An array of SearchSaveResponse objects from which select options will be created.
 * @returns An array of objects with label and value properties representing the select options.
 */
export const createSelectOptions = (savedSearches: SearchSaveResponse[]) => {
  return savedSearches
    .filter(item => item.title !== 'Sourcing Order')
    .map(item => ({
      label: item.title,
      value: item.unique_id,
    }));
};

/**
 * Creates combined search data based on the provided values.
 * @param values - The values used to create the combined search data.
 * @returns The combined search data containing the list title and searches to be combined.
 */
export const createCombineSearchData = (values: CombineSearchRequest) => {
  const searchesToCombine = [
    {
      search_id: values.first_search_id,
      logical_operator: values.searchFieldsList[0].operator.toUpperCase(),
    },
    ...values.searchFieldsList.map((search: any, index: number) => ({
      search_id: search.search_id,
      logical_operator: values.searchFieldsList[index + 1]?.operator.toUpperCase() || null,
    })),
  ];

  return {
    list_title: values.searchName,
    searches: searchesToCombine,
  };
};

/**
 * Returns the ordinal number as a string for the given input number.
 * @param number - The input number for which the ordinal number is to be determined.
 * @returns The ordinal number as a string, e.g. "1st", "2nd", "3rd", "4th", etc.
 */
export const getOrdinalNumber = (number: number) => {
  const mod100 = number % 100;
  const mod10 = number % 10;

  if (mod10 === 1 && mod100 !== 11) {
    return number + 'st';
  } else if (mod10 === 2 && mod100 !== 12) {
    return number + 'nd';
  } else if (mod10 === 3 && mod100 !== 13) {
    return number + 'rd';
  } else {
    return number + 'th';
  }
};

/**
 * Determines whether to fetch data after deleting saved search.
 * We do refetch if deleted search is parent for other combined search.
 * If dependent search is deleted, the combined search will be automatically deleted as well.
 * @param uniqueId - The unique identifier of the search to be deleted.
 * @param savedSearches - The list of saved searches to check for parent search IDs.
 * @returns A boolean indicating whether to fetch data after deleting the search.
 */
export const fetchAfterDelete = (
  uniqueId: number | string,
  savedSearches: SearchSaveResponse[]
) => {
  return savedSearches.some(search => search.parent_search_ids?.includes(Number(uniqueId)));
};

/**
 * Extracts and returns a numerical ID from a given filter string.
 *
 * This function searches for a specific pattern (`combined_search_id=<number>`)
 * within the provided filter string and returns the corresponding numerical ID.
 * If the pattern is not found, it returns 0.
 *
 * @param {string | undefined} filters - The filter string that may contain the ID.
 * @returns {number} - The extracted numerical ID or 0 if the pattern is not found.
 */
export const getIdFromFilters = (filters: string | undefined): number => {
  return Number(filters?.match(/combined_search_id=(\d+)/)?.[1] || 0);
};
