import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  padding: 0 18px;

  .ant-btn {
    border-color: $blue-6;
    color: $blue-6;
  }
  .ant-card-head {
    padding: 0px;
    border: 0 none;
    font-weight: 700;
  }
  .content-row {
    margin-bottom: 15px;
  }

  .relationship-summary {
    height: 100%;

    .ant-card-body {
      padding: 8px 10px;
      height: 100%;
    }

    &__section-title {
      color: var(--gray-6);
      font-weight: bold;
    }

    &__title {
      color: #334d6e;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      max-width: 90px;
    }

    &__value {
      color: #1890ff;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 7px;

      .text-truncate {
        line-height: 1.2;
        span {
          display: block;
          margin-top: -5px;
        }
      }
    }
  }

  .sub-info {
    font-size: 12px;
    font-weight: 400;

    &-value {
      color: #000000;
    }

    &-title {
      color: #90a0b7;
    }
  }

  &__sub-info {
    font-size: 12px;
    line-height: 10px;
  }

  &__title {
    color: $lightdark7;
  }

  &__value {
    font-size: 16px;
    font-weight: bold;
    color: $blue-6;

    &.highlighted {
      color: $warning;
    }
  }

  &__section-title {
    padding: 20px 0 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: $gray-9;

    &.no-content {
      padding-top: 5px;
    }
  }

  .company-source-descriptions__text {
    padding-bottom: 18px;
  }
`;

export const Highlights = styled.span`
  h3 {
    color: #90a0b7;
  }
`;

export const EllipsisExpander = styled.button`
  background-color: #eaecef;
  border: 0;
  border-radius: 1px;
  color: #444d56;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 12px;
  line-height: 6px;
  padding: 0 5px 5px;
  text-decoration: none;
  vertical-align: middle;
`;
