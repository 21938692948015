import React from 'react';
import { ColumnType } from 'antd/lib/table';
// constants
import globalConfig from '@optx/constants/config';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import {
  YEARS_LABELS,
  generateArrCurrentColumnKey,
  generateEbitdaCurrentColumnKey,
  generateGrowthCurrentColumnKey,
} from '@optx/constants/search';
import {
  DEFAULT_CELL_VALUE,
  DEFAULT_LAST_TOUCH,
  DEFAULT_NEXT_TOUCH,
} from '@optx/constants/table/cells';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
// models
import { CompanyKeys } from '@optx/models/table/Columns';
import Company, {
  CompanyProfile,
  CompanySourceTag,
  URLStatus,
  ProductCategory,
  CompanyUserValuesKeys,
} from '@optx/models/Company';
// services
import { CompanyService, UserService } from '@optx/services/api';
// utils
import { roundNumber } from '@optx/utils/number';
// components
import {
  EditFieldMultiSelectAsyncProductCategory,
  EditFieldRadio,
  EditFieldText,
  EditFieldDate,
  EditFieldSingleSelect,
} from '@optx/features/company/edit-fields/components';
import {
  TableCellListDefault,
  TableCellNumber,
  TableCellDate,
  TableCellDefaultTooltip,
  TableCellFormatMoney,
  TableCellListDate,
} from '@optx/components/common/table/AntTable/cells';
import {
  TableCellPercentageGrowth,
  TableCellStringList,
  TableCellScoreAdmin,
  TableCellOPTXScore,
  TableCellRadioAdmin,
  TableCellInvestors,
  TableCellDealTeams,
} from '@optx/components/common/table/Companies/cells';
import {
  TableCellNameLink,
  TableCellCompanyURL,
} from '@optx/components/common/table/Companies/styled-cells';
import TableCellEditFinancialField from '../cells/TableCellEditFinancialField';

interface CompanyColumnType extends ColumnType<CompanyProfile> {
  dataIndex: CompanyKeys;
}

const handleUnownedValue = (value: string | null, fieldName: string) => {
  if (['Sector', 'Sub-Sector'].includes(fieldName) && value === 'None') {
    return DEFAULT_EMPTY_VALUE;
  }

  if (fieldName === 'Fund' && !value) {
    return DEFAULT_EMPTY_VALUE;
  }

  return value;
};

/**
 * This columns will only use for automation testing.
 */
export const columns: Array<CompanyColumnType> = [
  {
    dataIndex: 'company_url',
    title: '',
    className: 'company_url',
    render: (value: string | null, record: Company) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
    width: 60,
    fixed: 'left',
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    className: 'company_name',
    sorter: true,
    render: (value: string, record: CompanyProfile) => (
      <TableCellNameLink value={value} record={record} />
    ),
    width: 240,
    fixed: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'score',
    title: 'OPTX Score',
    className: 'score',
    sorter: true,
    width: 150,
    render: (value: number, record: Company) => (
      <TableCellScoreAdmin record={record}>
        <TableCellOPTXScore company={record} />
      </TableCellScoreAdmin>
    ),
    fixed: 'left',
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'il_optx_score',
    title: 'IL OPTX Score',
    className: 'il_optx_score',
    sorter: true,
    width: 150,
    render: (value: number, record: Company) => (
      <TableCellScoreAdmin record={record}>
        <TableCellOPTXScore company={record} />
      </TableCellScoreAdmin>
    ),
    fixed: 'left',
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'parentcompany',
    className: 'parentcompany',
    title: 'Parent Company',
    sorter: true,
    render: (value: string) => <div>{value}</div>,
    width: 240,
    fixed: false,
    ellipsis: true,
  },
  {
    dataIndex: 'is_in_et',
    title: 'In Equity Touch',
    className: 'is_in_et',
    sorter: true,
    render: (value: boolean) => (value ? 'Yes' : 'No'),
    width: 85,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'company_description',
    title: 'Description',
    className: 'company_description',
    sorter: false,
    render: (value: string) => <div>{value}</div>,
    width: 520,
    ellipsis: true,
  },
  {
    dataIndex: 'year_founded',
    title: 'Year Founded',
    className: 'year_founded',
    sorter: true,
    width: 90,
    align: 'left',
    ellipsis: true,
    render: (value: string, record: Company) => (
      <div>{record.year_founded === 'None' ? DEFAULT_CELL_VALUE : record.year_founded}</div>
    ),
  },
  {
    dataIndex: 'is_software',
    title: 'Software',
    className: 'is_software',
    sorter: true,
    width: 85,
    render: (value: boolean | null) => (
      <div>
        {value?.toString() === 'true' ? 'Yes' : value?.toString() === 'false' ? 'No' : 'Unknown'}
      </div>
    ),
  },
  {
    dataIndex: 'is_interesting',
    title: 'PSG Fit',
    className: 'is_interesting',
    sorter: true,
    render: (value: boolean | null, record: Company, index: number) => (
      <TableCellRadioAdmin
        value={value}
        record={record}
        fieldName="PSG Fit"
        service={UserService.updateFitEvaluation}
        successMessage="Company PSG fit updated successfully!"
        errorMessage="Failed to update value!"
        destroyOnHide
        isFromGrid
      />
    ),
    width: 110,
    align: 'center',
    ellipsis: true,
  },
  {
    dataIndex: 'diversity',
    title: 'Diversity Spotlight',
    className: 'diversity',
    sorter: true,
    render: TableCellStringList,
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'addon_bool',
    title: 'Show Add-Ons',
    className: 'addon_bool',
    sorter: true,
    render: (value: boolean) => (value ? 'Yes' : 'No'),
    width: 150,
    ellipsis: true,
  },
  {
    dataIndex: 'addon',
    title: 'Add-On for a Specific Company',
    className: 'addon',
    sorter: true,
    render: (value: string | null) => <div>{value}</div>,
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'date_presented',
    title: 'Date Presented',
    className: 'date_presented',
    sorter: true,
    render: (value: string | null) => (
      <div>{value?.length === 0 ? DEFAULT_EMPTY_VALUE : value}</div>
    ),
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'company_owner',
    title: 'Company Owner',
    className: 'company_owner',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Company Owner')
      );
    },
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'senior_advisor_thesis',
    title: 'Senior Advisor Thesis',
    className: 'senior_advisor_thesis',
    sorter: true,
    render: (value: string | null) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Senior Advisor Thesis')
      );
    },
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'lead_source',
    title: 'Lead Source',
    className: 'lead_source',
    sorter: true,
    render: (value: string | null) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Lead Source')
      );
    },
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'pipeline_rank',
    title: 'Pipeline Rank',
    className: 'pipeline_rank',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Pipeline Rank')
      );
    },
    width: 85,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'stage',
    title: 'Stage',
    className: 'stage',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Stage')
      );
    },
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'fund',
    title: 'Fund',
    className: 'fund',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Fund')
      );
    },
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'num_employees',
    title: 'Number of Employees',
    className: 'num_employees',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={CompanyService.updateCompanyNumOfEmployees}
        fieldName="Number of Employees"
      />
    ),
    width: 120,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'emp_growth_percent',
    title: 'Employee Growth Percentage Y/Y',
    className: 'emp_growth_percent',
    sorter: true,
    render: (value: number | string, record: Company) =>
      value !== null && value !== '-' ? (
        <TableCellPercentageGrowth value={value} record={record} />
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
    width: 150,
    align: 'right',
    ellipsis: true,
  },

  {
    dataIndex: 'job_openings',
    title: 'Job Openings',
    className: 'job_openings',
    sorter: true,
    width: 90,
    align: 'right',
    render: (value: string | null) => <div>{value}</div>,
    ellipsis: true,
  },
  {
    dataIndex: 'job_openings_percent',
    title: 'Job Openings %',
    className: 'job_openings_percent',
    sorter: true,
    render: (value: string, record: Company) => (
      <TableCellPercentageGrowth value={value} record={record} />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'sw_website_rank',
    title: 'WW Website Rank',
    className: 'sw_website_rank',
    sorter: true,
    render: (value: string, record: Company) => <TableCellNumber value={value} record={record} />,
    width: 140,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'sw_website_rank_per_country',
    title: 'US Website Rank',
    className: 'sw_website_rank_per_country',
    sorter: true,
    render: (value: string, record: Company) => <TableCellNumber value={value} record={record} />,
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'opportunity_type',
    title: 'Opportunity Type',
    className: 'opportunity_type',
    sorter: true,
    width: 110,
    ellipsis: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Opportunity Type')
      );
    },
  },
  {
    dataIndex: 'company_type',
    title: 'Company Type',
    className: 'company_type',
    sorter: true,
    width: 110,
    ellipsis: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Company Type')
      );
    },
  },
  {
    dataIndex: 'sector',
    title: 'Sector',
    className: 'sector',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Sector')
      );
    },
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'sub_sector',
    title: 'Sub-sector',
    className: 'sub_sector',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Sub-sector')
      );
    },
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'product_category',
    title: 'Product Category',
    className: 'product_category',
    sorter: true,
    render: (value: ProductCategory[], company: Company) => (
      <EditFieldMultiSelectAsyncProductCategory
        fieldName="Product Category"
        value={value}
        record={company}
        service={CompanyService.productCategory}
        fieldKey={'product_category' as CompanyUserValuesKeys}
        asyncSearchEndpoint="/search/company_product_category"
        successMessage="Product category updated successfully!"
        errorMessage="Product category update failed, Server error!"
        isFromGrid
      />
    ),
    width: 250,
    ellipsis: true,
  },

  {
    dataIndex: 'sub_vertical',
    title: 'Sub Vertical',
    className: 'sub_vertical',
    sorter: true,
    render: TableCellStringList,
    width: 250,
    ellipsis: true,
  },
  {
    dataIndex: 'raise_date',
    title: 'Last Funding',
    className: 'raise_date',
    sorter: true,
    render: (value: string, record: CompanyProfile) => (
      <EditFieldDate
        value={value}
        fieldKey="raise_date"
        fieldName="Last Funding Date"
        service={UserService.updateCompanyUserValues}
        isFromGrid
        record={record}
        format={globalConfig.grid_date.DATE_FORMAT}
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'last_round',
    title: 'Last Round',
    className: 'last_round',
    sorter: true,
    render: TableCellDefaultTooltip,
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'last_raised_amount',
    title: 'Last Raised Amount',
    className: 'last_raised_amount',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={UserService.updateCompanyUserValues}
        fieldName="Last Raised Amount"
      />
    ),
    width: 130,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_investors',
    title: 'Investors',
    className: 'last_investors',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellInvestors value={value} record={record} />
    ),
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'capital_raised',
    title: 'Total Raised',
    className: 'capital_raised',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={UserService.updateCompanyUserValues}
        fieldName="Capital Raised"
      />
    ),
    width: 210,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_rev_update_amount',
    title: 'Revenue',
    className: 'last_rev_update_amount',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="revenue_list"
        fieldName="Revenue"
        filterName="last_rev_update_amount"
        extraKeys={['last_rev_update_amount', 'last_rev_update_year']}
        service={UserService.updateCompanyUserValues}
        isFromGrid={true}
      />
    ),
    width: 130,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_gross_margin',
    title: 'GM %',
    className: 'last_gross_margin',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="gross_margin_list"
        fieldName="GM %"
        filterName="last_gross_margin"
        service={CompanyService.addOpportunityPresentation}
        isFromGrid={true}
      />
    ),
    width: 100,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_rev_growth',
    title: 'Growth %',
    className: 'last_rev_growth',
    sorter: true,
    width: 100,
    align: 'left',
    ellipsis: true,
    render: (value: number | string, record: Company, index: number) =>
      value !== null && value !== undefined && value !== DEFAULT_EMPTY_VALUE ? (
        <TableCellPercentageGrowth value={value} record={record} />
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
  },
  {
    dataIndex: generateGrowthCurrentColumnKey,
    title: `${YEARS_LABELS.current} Growth %`,
    className: generateGrowthCurrentColumnKey,
    sorter: true,
    width: 100,
    align: 'left',
    ellipsis: true,
    render: (value: number | string | undefined, record: Company, index: number) =>
      value !== null && value !== undefined && value !== DEFAULT_EMPTY_VALUE ? (
        <TableCellPercentageGrowth value={value} record={record} />
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
  },
  {
    dataIndex: 'last_arr_value',
    title: 'ARR',
    className: 'last_arr_value',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="annual_recurring_revenue"
        fieldName="ARR"
        filterName="last_arr_value"
        service={CompanyService.addOpportunityPresentation}
        isFromGrid={true}
      />
    ),
    width: 100,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: generateArrCurrentColumnKey,
    title: `${YEARS_LABELS.current} ARR`,
    className: generateArrCurrentColumnKey,
    align: 'right',
    width: 150,
    sorter: true,
    render: (value: number | string | undefined, record: Company) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="annual_recurring_revenue"
        fieldName="ARR"
        filterName="last_arr_value"
        service={CompanyService.addOpportunityPresentation}
        isFromGrid={true}
        customFinancialYear={Number(YEARS_LABELS.current)}
      />
    ),
  },
  {
    dataIndex: 'ebitda_amount',
    title: 'EBITDA',
    className: 'ebitda_amount',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="ebitda_list"
        extraKeys={['ebitda_amount', 'ebitda_year']}
        fieldName="EBITDA"
        filterName="ebitda_numeric"
        service={UserService.updateCompanyUserValues}
        isFromGrid={true}
      />
    ),
    width: 130,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'deal_type',
    title: 'Deal Type',
    className: 'deal_type',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        value={value || DEFAULT_EMPTY_VALUE}
        record={record}
        fieldName="Deal Type"
        successMessage="Deal Type updated successfully!"
        errorMessage="Deal Type update failed, Server error!"
        service={CompanyService.addOpportunityPresentation}
        fieldKey={'deal_type' as CompanyUserValuesKeys}
      />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: generateEbitdaCurrentColumnKey,
    title: `${YEARS_LABELS.current} EBITDA`,
    className: generateEbitdaCurrentColumnKey,
    align: 'right',
    width: 150,
    sorter: true,
    render: (value: number | string | undefined, record: Company) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="ebitda_list"
        fieldName="EBITDA"
        filterName="ebitda_numeric"
        extraKeys={['ebitda_amount', 'ebitda_year']}
        service={UserService.updateCompanyUserValues}
        isFromGrid={true}
        customFinancialYear={Number(YEARS_LABELS.current)}
      />
    ),
  },
  {
    dataIndex: 'last_valuation',
    title: 'Valuation',
    className: 'last_valuation',
    sorter: true,
    render: (value: number, record: Company) => (
      <TableCellFormatMoney
        value={value}
        record={record}
        formatValue={val => roundNumber(val, `$${NUMBER_FORMAT}`).toUpperCase()}
      />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_touch_date',
    title: 'Last Touch',
    className: 'last_touch_date',
    sorter: true,
    render: (value: string, record: Company) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={
          value !== DEFAULT_LAST_TOUCH && value !== DEFAULT_NEXT_TOUCH ? undefined : value
        }
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'next_touch_date',
    title: 'Scheduled Touch',
    className: 'next_touch_date',
    sorter: true,
    render: (value: string | string[], record: Company, index: number) => (
      <TableCellListDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={typeof value === 'string' ? value : undefined}
      />
    ),
    width: 220,
    ellipsis: true,
  },
  {
    dataIndex: 'next_touch_initiator_name',
    title: 'Scheduled Touch Initiated By',
    className: 'next_touch_initiator_name',
    sorter: true,
    width: 240,
    render: (value: Array<string>, record: Company, index: number) => (
      <TableCellListDefault value={value} record={record} />
    ),
    ellipsis: true,
  },
  {
    dataIndex: 'last_touch_initiator_name',
    title: 'Last Touch Initiated By',
    className: 'last_touch_initiator_name',
    sorter: true,
    width: 160,
    render: (value: string | null) => <div>{value}</div>,
    ellipsis: true,
  },
  {
    dataIndex: 'current_ceo',
    title: 'CEO',
    className: 'current_ceo',
    sorter: true,
    render: TableCellDefaultTooltip,
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'ceo_approval',
    title: 'CEO Approval',
    className: 'ceo_approval',
    sorter: true,
    width: 90,
    align: 'right',
    render: (value: string | null) => <div>{value}</div>,
    ellipsis: true,
  },
  {
    dataIndex: 'g2_rating',
    title: 'G2Crowd Rating',
    className: 'g2_rating',
    sorter: true,
    width: 90,
    align: 'right',
    render: (value: string | null) => <div>{value}</div>,
    ellipsis: true,
  },
  {
    dataIndex: 'g2_review_count',
    title: 'G2Crowd Reviews',
    className: 'g2_review_count',
    sorter: true,
    width: 90,
    align: 'right',
    render: (value: string | null) => <div>{value}</div>,
    ellipsis: true,
  },
  {
    dataIndex: 'ct_rating',
    title: 'Capterra Rating',
    className: 'ct_rating',
    sorter: true,
    width: 90,
    align: 'right',
    render: (value: string | null) => <div>{value}</div>,
    ellipsis: true,
  },
  {
    dataIndex: 'ct_review_count',
    title: 'Capterra Reviews',
    className: 'ct_review_count',
    sorter: true,
    width: 90,
    align: 'right',
    render: (value: string | null) => <div>{value}</div>,
    ellipsis: true,
  },
  {
    dataIndex: 'cb_rank',
    title: 'Crunchbase Rank',
    className: 'cb_rank',
    sorter: true,
    render: (value: number | null, record: Company) => (
      <TableCellNumber value={value} record={record} />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'city',
    title: 'Town/City',
    className: 'city',
    sorter: true,
    render: TableCellDefaultTooltip,
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'state',
    title: 'State/Province',
    className: 'state',
    sorter: true,
    render: TableCellDefaultTooltip,
    width: 130,
    ellipsis: true,
  },
  {
    dataIndex: 'country',
    title: 'Country',
    className: 'country',
    sorter: true,
    render: TableCellDefaultTooltip,
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'source_tag',
    title: 'Source',
    ellipsis: false,
    className: 'source_tag',
    sorter: true,
    render: (value: Array<CompanySourceTag>, record: Company) => (
      <TableCellListDefault value={value} record={record} shouldBeInUpperCase />
    ),
    width: 180,
  },
  {
    dataIndex: 'linkedin',
    title: 'Linkedin Profile',
    className: 'linkedin',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={CompanyService.updateLinkedinURL}
        fieldName="Linkedin Profile"
        entityType="company"
        isURL
      />
    ),
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'is_in_business',
    title: 'In Business',
    className: 'is_in_business',
    sorter: true,
    render: (value, record, index) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="In Business"
        service={UserService.addCompanyInBusinessEvaluation}
        successMessage="Company in business state updated successfully!"
        errorMessage="Failed to update company in business state!"
        isFromGrid
      />
    ),
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'active',
    title: 'Active on Pipeline',
    className: 'active',
    sorter: true,
    render: (value, record, index) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="Active on Pipeline"
        service={UserService.updateActive}
        successMessage="Company active state updated successfully!"
        errorMessage="Failed to update company active state!"
        isFromGrid
      />
    ),
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'url_status',
    title: 'Website',
    className: 'url_status',
    sorter: true,
    render: (value: URLStatus | null, record: Company) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="Website Status"
        service={UserService.updateWebsiteActive}
        successMessage="Company website status updated successfully!"
        errorMessage="Failed to update value!"
        isFromGrid
        isFromHistory
      />
    ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'next_steps',
    title: 'Next Steps',
    className: 'next_steps',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return value ? (
        <div>{value === 'None' ? DEFAULT_EMPTY_VALUE : value}</div>
      ) : (
        handleUnownedValue(value, 'Next Steps')
      );
    },
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'created_on_optx',
    title: 'Date Created',
    className: 'created_on_optx',
    sorter: true,
    render: (value: string, record: Company) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={!value || value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : undefined}
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'deal_team',
    title: 'Deal Team',
    className: 'deal_team',
    sorter: true,
    render: (value: number[] | null) => <TableCellDealTeams value={value} fieldType="Deal Team" />,
    width: 150,
    ellipsis: true,
  },
  {
    dataIndex: 'senior_deal_team_lead',
    title: 'Senior Deal Team Lead',
    className: 'senior_deal_team_lead',
    sorter: true,
    render: (value: string) => (
      <TableCellDealTeams value={value} fieldType="Senior Deal Team Lead" />
    ),
    width: 150,
    ellipsis: true,
  },
  {
    dataIndex: 'asking_amount',
    title: 'Equity Check (ask amount)',
    className: 'asking_amount',
    sorter: true,
    render: (value: number, record: Company, index: number) => (
      <EditFieldText
        record={record}
        value={(value as number) / 1000000 || ''}
        isFromGrid
        fieldName="Asking Amount"
        service={CompanyService.addOpportunityPresentation}
      />
    ),
    width: 200,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'prev_stage',
    className: 'prev_stage',
    title: 'Previous Stage',
    sorter: true,
    render: TableCellDefaultTooltip,
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'is_ai_ml',
    title: 'AI / ML',
    className: 'is_ai_ml',
    sorter: true,
    render: (value: boolean | null, record: Company, index: number) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="AI / ML"
        service={UserService.updateAiMl}
        successMessage="Company AI / ML updated successfully!"
        errorMessage="Failed to update value!"
        isFromGrid
      />
    ),
    width: 110,
    align: 'center',
    ellipsis: true,
  },
];

export default columns;
