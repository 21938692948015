import { createSelector } from 'reselect';
import { Dictionary } from '@reduxjs/toolkit';
// models
import { UserInformationState } from './interfaces';
import { GridKeys } from '@optx/models/grid';
import { UserSessionSettings } from '@optx/models/user';
// constants
import { defaultAvatarImage } from '@constants/images';
// utils
import { getIdFromFilters } from '@optx/utils/combineSearch';

const selectUserInformation = (state: any) => state.user as UserInformationState;

export const shouldFetch = createSelector(
  selectUserInformation,
  state => !state.loading && !state.fetchedAt
);

export const isLoading = createSelector(selectUserInformation, state => state.loading);

export const loaded = createSelector(
  selectUserInformation,
  state => !!state.fetchedAt || !!state.error
);

export const authorizedComponents = createSelector(
  selectUserInformation,
  state => state.authorizedComponents
);

// get user role
const checkRole = (components: Dictionary<boolean>, role: string) => {
  return Object.keys(components).includes(role);
};

export const getIsAdminOrAnalyst = createSelector(selectUserInformation, state => {
  const components = state.authorizedComponents;

  return checkRole(components, 'admin_portal_link') || checkRole(components, 'analyst_general');
});

export const getIsAnalyst = createSelector(selectUserInformation, state => {
  const components = state.authorizedComponents;

  return (
    checkRole(components, 'analyst_general') &&
    !checkRole(components, 'admin_portal_link') &&
    !checkRole(components, 'partner_user')
  );
});

export const getHasAccessToPipelineReport = createSelector(selectUserInformation, state => {
  const components = state.authorizedComponents;

  return checkRole(components, 'pipeline_report');
});

export const getHasAccessToSourcingOutreachSummary = createSelector(
  selectUserInformation,
  state => {
    const components = state.authorizedComponents;

    return checkRole(components, 'sourcing_outreach');
  }
);

export const getHasAccessToChecklistResearchInsightsReport = createSelector(
  selectUserInformation,
  state => {
    const components = state.authorizedComponents;

    return checkRole(components, 'checklist_research_insights');
  }
);

export const getHasAccessToReviewCompany = createSelector(selectUserInformation, state => {
  const components = state.authorizedComponents;

  return checkRole(components, 'review_access');
});

export const getHasAccessToTouches = createSelector(selectUserInformation, state => {
  const components = state.authorizedComponents;

  return checkRole(components, 'touch_access');
});

export const getIsPartner = createSelector(selectUserInformation, state => {
  const components = state.authorizedComponents;

  return (
    checkRole(components, 'partner_user') &&
    !checkRole(components, 'analyst_general') &&
    !checkRole(components, 'admin_portal_link')
  );
});

export const getIsPartnerOnly = createSelector(selectUserInformation, state => {
  const components = state.authorizedComponents;

  return checkRole(components, 'partner_user') && !checkRole(components, 'admin_portal_link');
});

export const getResultsPerPage = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.search_results_per_page : 50
);

export const getResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.search_results_page_number : undefined
);

export const getContactResultsPerPage = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.contact_results_per_page : 50
);

export const getContactResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.contact_results_page_number : undefined
);

export const getMyCompaniesResultsPerPage = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.my_companies_results_per_page : 50
);

export const getSourcingOutReachPerPage = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.sourcing_outreach_results_per_page : 50
);

export const getMyCompaniesResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.my_companies_results_page_number : undefined
);

export const getSourcingOutReachResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data
    ? state.data.settings?.session_settings.sourcing_outreach_results_page_number
    : undefined
);

export const getAdvancedSearchSearchTitle = createSelector(selectUserInformation, state => {
  return state.data
    ? decodeURIComponent(
        state.data.settings?.session_settings.company_filters
          ?.split('&')
          .filter(item => !item.search('search_title='))[0]
          ?.split('=')[1] || ''
      )
    : '';
});

export const getCombinedSearchId = (gridKey: GridKeys) =>
  createSelector([selectUserInformation], (state: UserInformationState) => {
    switch (gridKey) {
      case 'advancedSearch':
        return getIdFromFilters(state.data?.settings?.session_settings.company_filters);
      case 'myCompanies':
        return getIdFromFilters(state.data?.settings?.session_settings.my_companies_filters);
      case 'sslists':
      case 'watchlists':
        return getIdFromFilters(state.data?.settings?.session_settings.user_list_filters);
      case 'outreach':
        return getIdFromFilters(
          state.data?.settings?.session_settings.sourcing_outreach_detail_filters
        );
      default:
        return 0;
    }
  });

export const getUserName = createSelector(
  selectUserInformation,
  state => (state.data && state.data.profile.login_name) || 'default'
);

export const getFullName = createSelector(selectUserInformation, state =>
  state.data ? state.data.profile.display_name : ''
);

export const getFirstName = createSelector(selectUserInformation, state =>
  state.data ? state.data.profile.display_name.split(' ')[0] : ''
);

export const getLastName = createSelector(selectUserInformation, state =>
  state.data ? state.data.profile.display_name.split(' ')[1] : ''
);

export const getEmail = createSelector(selectUserInformation, state =>
  state.data ? state.data.profile.email : ''
);

export const getPicture = createSelector(
  selectUserInformation,
  state => state.picture || defaultAvatarImage
);

export const getAnalyticsId = createSelector(
  selectUserInformation,
  state => (state.data && state.data.settings?.ga) || ''
);

export const getUserId = createSelector(
  selectUserInformation,
  state => state.data && state.data.profile.user_id
);

export const getUserEtId = createSelector(
  selectUserInformation,
  state => state.data && state.data.profile.et_user_id
);

// region options
export const getRegionOptions = createSelector(selectUserInformation, state => state.regionOptions);

export const shouldFetchRegionOptions = createSelector(
  selectUserInformation,
  state => !state.regionOptions.loading && !state.regionOptions.fetchedAt
);

// user region settings
export const getRegionSettingsInitialValues = createSelector(selectUserInformation, state => {
  if (!state.data?.settings.region_settings) {
    return {
      short_date: '',
      grid_date: '',
    };
  }

  const data = {
    short_date: state.data.settings.region_settings.short_date.id,
    grid_date: state.data.settings.region_settings.grid_date.id,
  };

  return data;
});

export const getUserRegionSettings = createSelector(
  selectUserInformation,
  state => state.data?.settings.region_settings
);

export const showQuickFilters = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.show_quick_filters
);

export const showMyCompaniesCards = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.show_my_companies_cards
);

export const filtersRefresh = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.filters_refresh
);

export const myCompaniesFiltersRefresh = createSelector(
  selectUserInformation,
  state => !!state.data && (state.data.settings?.my_companies_filters_refresh || false)
);

export const menuCollapsed = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.menu_collapsed
);

export const getUserSettings = createSelector(selectUserInformation, state => state.data);

export const getSettings = createSelector(selectUserInformation, state => state.data?.settings);

export const getSessionSettings = createSelector(
  selectUserInformation,
  state => state.data?.settings?.session_settings || ({} as UserSessionSettings)
);

// User list
export const showUserListQuickFilters = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.user_list_show_quick_filters
);

export const getUserListResultsPerPage = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.user_list_search_results_per_page : 50
);

export const getFavoritesListResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.favorites_list_page_number : undefined
);

export const getSourceScrubListResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.source_scrub_list_page_number : undefined
);

export const getSavedCompanySearchesResultsPageNumber = createSelector(
  selectUserInformation,
  state =>
    state.data ? state.data.settings?.session_settings.saved_company_serches_page_number : undefined
);

export const getSavedContactSearchesResultsPageNumber = createSelector(
  selectUserInformation,
  state =>
    state.data ? state.data.settings?.session_settings.saved_contact_serches_page_number : undefined
);

export const getUserListSearchesResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data
    ? state.data.settings?.session_settings.user_list_search_results_page_number
    : undefined
);

export const getSSListSearchesResultsPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.ss_list_search_results_page_number : undefined
);

export const getETCompaniesListPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.new_et_companies_page_number : undefined
);

export const getAddonPageNumber = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.addon_results_page_number : undefined
);

export const getAddonPerPage = createSelector(selectUserInformation, state =>
  state.data ? state.data.settings?.session_settings.addon_results_per_page : undefined
);

export const userListfiltersRefresh = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.user_list_filters_refresh
);

export const columnWidthsAdvancedSearch = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.column_widths_advanced_search
);

export const columnWidthsLists = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.column_widths_lists
);

export const columnWidthsMyCompanies = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.column_widths_my_companies
);

export const getDefaultRecommendedOptxNotifications = createSelector(
  selectUserInformation,
  state => !!state.data && state.data.settings?.alert_settings
);

export const getDefaultScore = createSelector(
  selectUserInformation,
  state => (!!state?.data && state.data.settings?.profile_settings?.optx_score_settings) || 'us'
);

export const getDefaultCustomUIView = createSelector(
  selectUserInformation,
  state => state.data?.settings.default_view
);

export const getProfileInformation = createSelector(selectUserInformation, state => {
  if (state.data) {
    const name = state.data.profile.display_name || '';
    const email = state.data.profile.email || '';
    const picture = state.picture || defaultAvatarImage;
    const role = state.data.profile.user_role || '';
    const title = state.data.profile.user_title || '';
    const permissionGroups = state.data.profile.permission_groups || [];

    return {
      name,
      email,
      picture,
      role,
      permissionGroups,
      title,
    };
  }

  return {};
});

export const getSourcingOutReachDefaultAnalyst = createSelector(selectUserInformation, state => {
  if (
    !state.data?.profile.permission_groups.includes('Sourcing Outreach') &&
    state.data?.profile.user_role === 'Analyst'
  ) {
    return state.data?.profile.display_name;
  }

  return null;
});

export const getPipelineReportQuery = createSelector(
  selectUserInformation,
  state => state.data?.settings.session_settings.pipeline_report || ''
);
