import { call, put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, pick } from 'lodash';
import queryString from 'query-string';
import { push } from 'react-router-redux';
import { AxiosResponse } from 'axios';
// constants
import { BULK_ADD_COMPANY_LIMIT_EQUITY_TOUCH } from '@optx/config/bulkConfig';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// models
import { SelectOption } from '@optx/models/Option';
import {
  SelectedCompanies,
  CompanyBulkData,
  EquityTouchBulkResponse,
  EquityTouchBulkCompanies,
  ResolveSalesloft,
  UpdateCompanyAndSubmit,
  EquityTouchBulkResults,
  EquityTouchBulkAutoSave,
  SelectedCompaniesData,
  ResolveSalesloftSuccess,
} from '@models/bulkActions';
import { CompanyProfile } from '@models/Company';
import { SalesloftRequest, SalesloftCadences, SalesloftContact } from '@optx/models/Salesloft';
import {
  EquityTouchContact,
  EquityTouchFetchedField,
  EquityTouchSubmitField,
  GroupsSubmitField,
} from '@models/equityTouch';
import { CompanyUserContact } from '@optx/models/api/contacts';
import { UserSessionSettings } from '@optx/models/user';
// utils
import { mapEquityTouchProps, mapEquityTouchForm } from '@utils/equityTouch';
import { getErrorMessage } from '@utils/api/errors';
import { handleSalesloftError } from '@optx/utils/salesloft';
// services
import { CompanyService, SalesloftService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { selectors as salesLoftSelector } from '@redux/contacts/salesloft';
import { selectors as companyEditSelectors } from '@components/feature/company-individual-edit/state';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as salesLoftActions } from '@features/bulk-actions/salesloft';
import { actions as loaderActions } from '@features/custom-global-loader';
import { getSessionSettings } from '@redux/user/information/selectors';
import * as actions from './actions';

function* initializeEquityTouchBulkSaga(action: PayloadAction<SelectedCompaniesData>) {
  yield put(loaderActions.toggle({ loading: true }));
  const gridName = action.payload.grid;
  const listId = action.payload?.listId;
  const listType = action.payload?.listType;
  const defaultErrorMessage = 'Failed to fetch Equity Touch data!';
  const selectedCompanies: SelectedCompanies[] = yield select(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(gridName)
  );

  // if user refreshes page, because currently we don't save the selected companies,
  // redirect user to the grid from where he accessed the page
  if (!selectedCompanies.length) {
    const urlParams = queryString.parse(window.location.search.substr(1));
    yield put(push(urlParams.referrer as string));
  } else {
    const shouldFetch: boolean = yield select(bulkActionsSelectors.equityTouch.shouldFetch);
    const sessionSettings: UserSessionSettings = yield select(getSessionSettings);
    const sessionSettingsFormData =
      Object.keys((sessionSettings?.et_form_auto_save?.formData as {}) || {}).length === 0;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let formData: Dictionary<EquityTouchFetchedField> = yield select(
      bulkActionsSelectors.equityTouch.getFormData
    );

    try {
      if (shouldFetch && sessionSettingsFormData) {
        const res: AxiosResponse<Dictionary<EquityTouchFetchedField>> = yield call(
          CompanyService.fetchEquityTouchFormData,
          selectedCompanies[0].company_id
        );

        if (res.data) {
          yield put(actions.fetchFormDataSuccess(res.data, true));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          formData = res.data;

          yield put(salesLoftActions.fetchCompaniesContacts(gridName));

          const initialFormData: Dictionary<string | number | boolean | number[] | SelectOption> =
            yield select(bulkActionsSelectors.equityTouch.getInitialFormValues);

          const companiesById: EquityTouchBulkCompanies = {};
          const allIds: number[] = [];
          selectedCompanies.forEach(company => {
            const { company_id: id } = company;
            const companyData = mapEquityTouchProps(company);
            companiesById[id] = {
              ...companyData,
              ...initialFormData,
              contacts: company?.contacts,
              FTEs: companyData.FTEs as number,
              salesloft_contacts: company?.contacts?.filter(
                contact => contact.primary_contact
              ) as any,
              'Company Id': id,
              // flag for api to know whether to add the contacts from their side or
              // use the contact data sent from UI.
              add_all_contacts: true,
              'Equity Check':
                company.asking_amount && company.asking_amount !== DEFAULT_EMPTY_VALUE
                  ? Number(company.asking_amount.replace('$', '').replaceAll(',', ''))
                  : '',
            };
            allIds.push(id);
          });

          yield put(
            actions.initializeEquityTouchBulkSuccess({
              companiesById,
              allIds,
              ...(listId && { listId }),
              ...(listType && { listType }),
            })
          );
        } else {
          NotificationService.error(defaultErrorMessage);
        }
      }
    } catch (error: any) {
      const errorMessage = getErrorMessage(error, defaultErrorMessage);
      NotificationService.error(errorMessage);
    }
  }

  yield put(loaderActions.toggle({ loading: false }));
}

function* initializeIndividualEditingSaga(
  action: PayloadAction<Dictionary<string | number | number[] | boolean>>
) {
  const companyIds: number[] = yield select(bulkActionsSelectors.equityTouch.getAllIds);

  yield put(actions.fetchCompany(companyIds[0], true));
}

function* addToEquityTouchBulkSaga(action: PayloadAction<CompanyBulkData>) {
  const defaultErrorMessage = 'Failed to add to Equity Touch!';

  // @ts-ignore
  const editableFieldsOptions = yield select(
    // @ts-ignore
    companyEditSelectors.companyIndividualEdit.getAllOptions
  );
  const formData: Dictionary<EquityTouchFetchedField> = yield select(
    bulkActionsSelectors.equityTouch.getFormData
  );
  const companiesById: EquityTouchBulkCompanies = yield select(
    bulkActionsSelectors.equityTouch.getCompanies
  );
  const allIds: number[] = yield select(bulkActionsSelectors.equityTouch.getAllIds);
  const ids = [...allIds];
  const selectedCompaniesCount: number = yield select(
    bulkActionsSelectors.equityTouch.selectedCompaniesCount
  );
  const currentResults: EquityTouchBulkResults[] = yield select(
    bulkActionsSelectors.equityTouch.results
  );

  const idBatch = ids.splice(currentResults.length, BULK_ADD_COMPANY_LIMIT_EQUITY_TOUCH);
  // @ts-ignore
  const companyBatch: Dictionary<string | number | EquityTouchSubmitField | GroupsSubmitField>[] =
    idBatch.map((id: number) => ({
      // @ts-ignore
      ...mapEquityTouchForm(companiesById[id], formData),
      cadence: companiesById[id].cadence || '',
      // for contacts email data comes from api in a "email" prop,
      // but for equity touch we need to send email data in a "emails" prop
      contacts: (companiesById[id].contacts as EquityTouchContact[])?.map(
        (contact: Partial<CompanyUserContact>) => ({
          ...contact,
          emails: contact.email,
          email: undefined,
        })
      ),
      'Company Id': id,
      add_all_contacts: companiesById[id].add_all_contacts,
      salesloft_contacts: (companiesById[id]?.salesloft_contacts as SalesloftContact[])?.map(
        (contact: SalesloftContact) => {
          const updatedContact = {
            ...contact,
            company_name: companiesById[id]['Company Name']
              ? companiesById[id]['Company Name']
              : contact.company_name,
          };

          return pick(updatedContact, [
            'first_name',
            'last_name',
            'person_id',
            'title',
            'primary_email',
            'secondary_email',
            'tertiary_email',
            'quarternary_email',
            'company_name',
            'company_website',
            'phone',
            'phone_type',
            'additional_phones',
          ]);
        }
      ),
    }));

  try {
    const res: AxiosResponse<EquityTouchBulkResponse[]> = yield call(
      CompanyService.addEquityTouchBulk,
      companyBatch
    );

    if (res.data) {
      yield put(
        actions.updatePercentage(
          Math.round((BULK_ADD_COMPANY_LIMIT_EQUITY_TOUCH / selectedCompaniesCount) * 100)
        )
      );

      const results: EquityTouchBulkResults[] = res.data.map(result => ({
        company_id: result.et_status.company_id,
        company_url: result.et_status.company_url,
        raw_url: result.et_status.raw_url,
        company_name: result.et_status.company_name,
        et_sync_status: result.et_status.et_sync_status,
        et_message: result.et_status.et_message,
        et_resolve: result.et_status.et_resolve,
        et_url: result.et_status.et_url,
        salesloftContacts: result.salesloft_status,
      }));
      const finalResults: EquityTouchBulkResults[] = [...currentResults, ...results];

      yield put(actions.saveResults(results));

      const cancelSearch: boolean = yield select(bulkActionsSelectors.equityTouch.cancel);

      if (!cancelSearch) {
        if (allIds.length !== finalResults.length) {
          yield put(actions.addToEquityTouchBulk({ formValues: {} }));
        } else {
          yield put(actions.updateProgress(false));
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage(100));
          yield put(
            actions.addToEquityTouchBulkSuccess({
              results: finalResults,
              companies: companiesById,
              editableFields: editableFieldsOptions,
            })
          );
        }
      } else {
        const canceledCompanies: EquityTouchBulkResults[] = [];
        const prevResults: EquityTouchBulkResults[] = yield select(
          bulkActionsSelectors.equityTouch.results
        );

        const prevResultsIds = prevResults.map(result => result.company_id);
        const remainingIds = allIds.filter((id: number) => !prevResultsIds.includes(id));

        if (remainingIds?.length) {
          remainingIds.forEach((id: number) => {
            canceledCompanies.push({
              company_id: companiesById[id]['Company Id'] as number,
              company_url: companiesById[id].Website as string,
              raw_url: results.find(company => company.company_id === id)?.raw_url || null,
              company_name: companiesById[id]['Company Name'] as string,
              et_sync_status: false,
              et_message: 'Canceled by user',
              et_resolve: false,
              et_url: null,
              salesloftContacts: [
                {
                  person_id: null,
                  sl_message: null,
                  sl_resolve: null,
                  sl_sync_status: null,
                  sl_url: null,
                },
              ],
            });
          });
        }

        yield put(actions.saveResults(canceledCompanies));
        yield put(actions.updateProgress(false));
        yield put(actions.updateCompleted(true));
        yield put(actions.cancel(false));
        yield put(loaderActions.toggle({ loading: false, customText: '' }));
      }
    } else {
      NotificationService.error(defaultErrorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, defaultErrorMessage);
    yield put(actions.addToEquityTouchBulkFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* fetchCompanySaga(action: PayloadAction<{ companyId: number; fetchEqtData: boolean }>) {
  yield put(loaderActions.toggle({ loading: true }));
  const { companyId, fetchEqtData } = action.payload;
  const defaultErrorMessage = 'Failed to fetch company data!';

  try {
    const profileRes: AxiosResponse<false | CompanyProfile> = yield call(
      CompanyService.getProfile,
      companyId
    );

    if (profileRes.data) {
      yield put(actions.fetchCompanySuccess(profileRes.data));
    } else {
      NotificationService.error(defaultErrorMessage);
    }

    if (fetchEqtData) {
      const formRes: AxiosResponse<Dictionary<EquityTouchFetchedField<any>>> = yield call(
        CompanyService.fetchEquityTouchFormData,
        companyId
      );

      if (formRes.data) {
        yield put(actions.fetchFormDataSuccess(formRes.data, false, companyId));
      } else {
        NotificationService.error(defaultErrorMessage);
      }
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, defaultErrorMessage);
    NotificationService.error(errorMessage);
  }

  yield put(loaderActions.toggle({ loading: false }));
}

function* updateBulkContactSaga(
  action: PayloadAction<Partial<CompanyUserContact>, any, VoidFunction>
) {
  yield put(loaderActions.toggle({ loading: true }));

  const { payload: contact, meta: callback } = action;
  const { company_id: companyId } = contact;

  try {
    const res: AxiosResponse<Partial<CompanyUserContact>> = yield call(
      CompanyService.editPrimaryContact,
      {
        ...contact,
        company_id: companyId,
      }
    );

    if (res.data) {
      yield put(actions.updateBulkContactSuccess(contact));
      callback && callback();
    }
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to edit contact!');
    NotificationService.error(message);
  }

  yield put(loaderActions.toggle({ loading: false }));
}

function* updateBulkCompanySaga(action: PayloadAction<UpdateCompanyAndSubmit>) {
  const { shouldSubmit } = action.payload;

  if (shouldSubmit) {
    yield put(actions.addToEquityTouchBulk({ formValues: {} }));
  }
}

function* resolveSalesloftSaga(action: PayloadAction<ResolveSalesloft>) {
  yield put(loaderActions.toggle({ loading: true }));

  const { companyId, all } = action.payload;
  const companiesById: EquityTouchBulkCompanies = yield select(
    bulkActionsSelectors.equityTouch.getCompanies
  );
  const cadences: SalesloftCadences[] = yield select(salesLoftSelector.getCadences);
  const results: EquityTouchBulkResults[] = yield select(bulkActionsSelectors.equityTouch.results);

  const company = companiesById[companyId];
  const cadence = cadences.find(
    (item: SalesloftCadences) => item.id === companiesById[companyId].cadence
  );

  const result = results.find(result => result.company_id === companyId);
  const failedContactIds = result?.salesloftContacts
    .filter(contact => contact.sl_resolve)
    ?.map(contact => contact.person_id);

  let contacts: SalesloftContact[] = [];

  if (
    all ||
    (result?.salesloftContacts.length === 1 && result?.salesloftContacts[0].person_id === null)
  ) {
    contacts = company?.salesloft_contacts as SalesloftContact[];
  } else if (failedContactIds) {
    contacts = (company?.salesloft_contacts as SalesloftContact[])?.filter(
      (contact: SalesloftContact) => failedContactIds.includes(contact.person_id as number)
    );
  }

  const requestData: SalesloftRequest = {
    cadence_id: cadence?.id,
    cadence_name: cadence?.name,
    team_cadence: cadence?.team_cadence,
    company_name: company['Company Name'] as string,
    company_website: company.Website as string,
    company_id: company['Company Id'] as number,
    contacts: contacts.map((contact: SalesloftContact) =>
      pick(contact, [
        'first_name',
        'last_name',
        'person_id',
        'title',
        'primary_email',
        'secondary_email',
        'tertiary_email',
        'quarternary_email',
      ])
    ),
  };

  try {
    const res: AxiosResponse<ResolveSalesloftSuccess> = yield call(
      SalesloftService.postSalesloftData,
      requestData
    );

    if (res) {
      yield put(actions.resolveSalesloftSuccess(res.data));
    }
  } catch (error: any) {
    const errorMessage = handleSalesloftError(
      error.response,
      'Failed to add contact to Sales Loft!'
    );
    NotificationService.error(errorMessage);
  }

  yield put(loaderActions.toggle({ loading: false }));
}

function* fetchContactsSaga(action: PayloadAction<number>) {
  yield put(loaderActions.toggle({ loading: true }));
  const companyId = action.payload;
  const defaultErrorMessage = 'Failed to fetch contacts!';

  try {
    const res: AxiosResponse<Array<CompanyUserContact>> = yield call(
      CompanyService.getPrimaryContacts,
      companyId
    );

    if (res.data) {
      yield put(actions.fetchContactsSuccess(res.data, companyId));
    }
  } catch (error: any) {
    const message = getErrorMessage(error, defaultErrorMessage);
    NotificationService.error(message);
  }

  yield put(loaderActions.toggle({ loading: false }));
}

export function* autoSaveSaga(action: PayloadAction<EquityTouchBulkAutoSave | null>) {
  try {
    yield put(userInformationActions.updateUserSettings({ et_form_auto_save: action.payload }));
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to edit primary contact!');
    NotificationService.error(message);
  }
}

export default function* equityTouchBulkSaga() {
  yield takeLatest(actions.initializeEquityTouchBulk, initializeEquityTouchBulkSaga);
  yield takeLatest(actions.initializeIndividualEditing, initializeIndividualEditingSaga);
  yield takeLatest(actions.addToEquityTouchBulk, addToEquityTouchBulkSaga);
  yield takeLatest(actions.fetchCompany, fetchCompanySaga);
  yield takeLatest(actions.updateBulkContact, updateBulkContactSaga);
  yield takeLatest(actions.updateBulkCompany, updateBulkCompanySaga);
  yield takeLatest(actions.resolveSalesloft, resolveSalesloftSaga);
  yield takeLatest(actions.fetchContacts, fetchContactsSaga);
  yield takeLatest(actions.autoSave, autoSaveSaga);
}
