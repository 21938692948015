import { call, takeLeading, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { logOut } from './actions';
// services
import NotificationService from '../../../services/NotificationService';
import { AuthService } from '@optx/services/api';
import { JWTStorage, MenuStorage, AnalystStorage } from '@optx/storage/localStorage';
// redux
import { selectors as cognitoLogoutSelectors } from '../cognitoLogout';
import { LogOutActionPayload } from '../interfaces';
import { getErrorMessage } from '@optx/utils/api/errors';
import { deleteCookie } from '@optx/redux/login/utils';

export function* logOutSaga(action: PayloadAction<LogOutActionPayload>) {
  NotificationService.forceLock = true;
  const logOutURL: string = yield select(cognitoLogoutSelectors.getCognitoLogoutURL);

  try {
    yield call(JWTStorage.removeJWT);
    yield call(MenuStorage.removeMenuCollapsed);
    yield call(AnalystStorage.removeIsAnalyst);
    yield call(deleteCookie, 'optx_token');
    yield call(AuthService.logout);
    window.location.replace(logOutURL);
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Logout failed');
    NotificationService.error(errorMessage);
  }
}

export default function* authSaga() {
  yield takeLeading(logOut, logOutSaga);
}
