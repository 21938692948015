import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { Layout } from 'antd';
import { CollapseType } from 'antd/lib/layout/Sider';
import { useSelector, useDispatch } from 'react-redux';
// constants
import { MENU_COLLAPSED } from '@optx/storage/localStorage/menuStorage';
import { isChrome } from '@optx/constants/os/browser';
// utils
import { getCookie } from '@optx/redux/login/utils';
// redux
import {
  selectors as fullScreenSelectors,
  actions as fullScreenActions,
} from '@redux/ui/settings/fullscreen';
import { selectors as cognitoLogoutSelectors } from '@redux/auth/cognitoLogout';
import { actions as filterActions, selectors as filterSelectors } from '@redux/company/filters';
import { selectors as routerSelectors } from '@redux/router';
// hooks
import {
  useFetchUserInformation,
  useFetchFavoriteLists,
  useInitFetchFavoriteLists,
  useInitFetchFavoriteListsPopup,
} from '@optx/common/hooks/init';
import { useShouldFetch } from '@optx/common/hooks/fetch';
import { useInject as useInjectShareList } from '@features/list-share';
import {
  useInjectUI,
  useInjectNotes,
  useInjectUserInformation,
  useInjectFavoriteLists,
  useInjectPrimaryContacts,
  useInjectCompanyFilterSources,
  useInjectHomePage,
  useInjectCompanyListSourcingOutReach,
} from '@optx/common/hooks/inject';
import { useInject as useInjectAlerts } from '@features/alerts';
import { useInjectPage as useInjectCompanyList } from '@components/pages/CompanyList/useInjectPage';
import { useInject as useInjectBulkActions } from '@features/bulk-actions';
import { useIsTabletOrSmaller } from '@optx/common/hooks/responsive';
import { useInject as useInjectAnalystLeaderboard } from '@components/pages/AnalystsLeaderboard';
import { useInject as useInjectListsAndSeaches } from '@features/lists-and-searches';
import { useInjectProfilePage } from '@components/pages/CompanyProfile/hooks';
import { useInjectGrid } from '@features/grid';
import useIsAnalyst from '@hooks/useIsAnalyst';
// storage
import { AnalystStorage, JWTStorage, MenuStorage } from '@optx/storage/localStorage';
// components
import MainNavigation from './MainNavigation';
import Analytics from '../../components/common/analytics';
import { LayoutContext } from './LayoutContext';
import AddResearchRationale from '@optx/features/add-research-rationale';

const useInject = () => {
  useInjectGrid();
  useInjectProfilePage();
  useInjectCompanyList();
  useInjectShareList();
  useInjectPrimaryContacts();
  useInjectAnalystLeaderboard();
  useInjectUI();
  useInjectBulkActions();
  useInjectNotes();
  useInjectUserInformation();
  useInjectCompanyFilterSources();
  useInjectHomePage();
  useInjectCompanyListSourcingOutReach();
  useInjectFavoriteLists();
  useInjectListsAndSeaches();
  useInjectAlerts();
};

interface MainLayoutProps {
  extensionLayout?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, extensionLayout }) => {
  const dispatch = useDispatch();

  useInject();

  useFetchUserInformation();
  useInitFetchFavoriteLists();
  useFetchFavoriteLists();
  useInitFetchFavoriteListsPopup();

  const shouldFetch = useSelector(filterSelectors.shouldFetchCompanyFilters);
  const { action } = useSelector(routerSelectors.getRouter);
  const fetchFilters = useCallback(() => dispatch(filterActions.fetchCompanyFilters()), [dispatch]);
  const { advancedSearchPathname } = useIsAnalyst();
  const logoutUrl = useSelector(cognitoLogoutSelectors.getCognitoLogoutURL);

  useShouldFetch(
    shouldFetch && !extensionLayout && window.location.pathname !== advancedSearchPathname,
    fetchFilters
  );

  const isTabletOrSmaller = useIsTabletOrSmaller();
  const isFullScreen = useSelector(fullScreenSelectors.isFullscreen);

  const menuCollapsed = localStorage.getItem(MENU_COLLAPSED);
  const [collapsed, setCollapsed] = useState(
    isTabletOrSmaller ? true : menuCollapsed !== null ? JSON.parse(menuCollapsed) : false
  );

  const handleCollapse = (collapsed: boolean, type: CollapseType) => {
    setCollapsed(collapsed);
    localStorage.setItem(MENU_COLLAPSED, collapsed.toString());
  };

  const layoutClassname = classnames('main-layout', {
    'has-sider-collapsed': collapsed,
    'is-fullscreen': isFullScreen,
  });

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === MENU_COLLAPSED && event.newValue !== null) {
      setCollapsed(JSON.parse(event.newValue));
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action === 'POP' || action === 'REPLACE') {
      isFullScreen && dispatch(fullScreenActions.toggleFullScreen());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, dispatch]);

  useEffect(() => {
    if (window.name === 'Cognito Login - Extension') {
      const token = JWTStorage.getJWT();
      const cookieToken = getCookie('optx_token');

      if (token && !cookieToken) {
        document.cookie = `optx_token=${token}; SameSite=None; Secure;`;
      }

      window.close();
    }
  }, []);
  useEffect(() => {
    // On small devices navigation is only collapsed.
    if (isTabletOrSmaller) {
      setCollapsed(true);
    } else if (!isTabletOrSmaller) {
      setCollapsed(menuCollapsed !== null ? JSON.parse(menuCollapsed) : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletOrSmaller]);

  if (isChrome) {
    //@ts-ignore
    window?.cookieStore?.addEventListener('change', (event: { deleted: any }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      for (const deletion of event.deleted) {
        if (deletion.name === 'optx_token') {
          JWTStorage.removeJWT();
          MenuStorage.removeMenuCollapsed();
          AnalystStorage.removeIsAnalyst();
          window.location.replace(logoutUrl);
        }
      }
    });
  }

  return (
    <Layout className={layoutClassname}>
      <LayoutContext.Provider value={{ menuCollapsed: collapsed }}>
        <Analytics />

        {!isFullScreen && !extensionLayout ? (
          <MainNavigation
            defaultCollapsed={collapsed}
            collapsed={collapsed}
            onCollapse={handleCollapse}
            collapsible={!isTabletOrSmaller}
          />
        ) : !extensionLayout ? (
          <AddResearchRationale />
        ) : null}
        <Layout className={collapsed ? 'right-section menu-collapsed' : 'right-section'}>
          {children}
        </Layout>
      </LayoutContext.Provider>
    </Layout>
  );
};

export default MainLayout;
