import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { SuccessErrorCallback } from '@optx/models/callback';
import { UserInformation } from '@optx/models/user';
import { CrossTabsLoginPayload, LoginPayload } from './interface';
// constants
import appRoutes from '@constants/routes';
// services
import NotificationService from '@services/NotificationService';
import { AuthService, UserService } from '@services/api';
// redux
import { actions as loaderActions } from '@redux/global-loader';
import { actions as userActions } from '@redux/user/information';
import * as actions from './actions';
// storage
import { setAxiosAuthorizationHeaders } from '../../modules/axios';
import { JWTStorage, MenuStorage, AnalystStorage } from '@optx/storage/localStorage';
import { getCookie } from './utils';

export function* loginSaga(action: PayloadAction<LoginPayload, any, SuccessErrorCallback>) {
  yield put(loaderActions.startConnecting());
  const callback = action.meta;

  try {
    const res: AxiosResponse<any> = yield call(AuthService.login, {
      headers: action.payload.headers,
    });

    if (res.data && res.data.data && res.data.data.jwt) {
      const token = res.data.data.jwt;
      document.cookie = `optx_token=${token}; SameSite=None; Secure;`;
      yield call(setAxiosAuthorizationHeaders, token);
      yield call(JWTStorage.setJWT, token);
      yield call(MenuStorage.setMenuCollapsed, false);

      if (action.payload.isChromeExtension) {
        yield put(push(appRoutes.chromeExtension));
      } else {
        /**
         * This section relates to the user's role.
         * If the user is an analyst, we need to redirect him from the start page to the 'analyst home' page.
         * Otherwise, redirect to the 'advanced search' page. For this purpose, we need to fetch user data.
         */
        const userDataRes: AxiosResponse<UserInformation> = yield call(UserService.getUserInfo);
        let isAnalyst = false;

        if (userDataRes.data) {
          yield put(userActions.fetchUserInformationSuccess(userDataRes.data));
          const { components } = userDataRes.data;

          if (
            components &&
            components.includes('analyst_general') &&
            !components.includes('admin_portal_link') &&
            !components.includes('partner_user')
          ) {
            isAnalyst = true;
          }
        } else {
          const errorMessage = 'Failed to fetch User Information!';
          yield put(userActions.fetchUserInformationFail(errorMessage));
          NotificationService.error(errorMessage);
        }

        AnalystStorage.setIsAnalyst(isAnalyst);

        yield put(push(appRoutes.home));
      }

      yield put(loaderActions.endConnecting());
    } else {
      yield put(loaderActions.endConnecting());
      NotificationService.error('Login failure!');
    }
  } catch (e: any) {
    yield put(loaderActions.endConnecting());

    if (e.response) {
      // handle error when receiving response from server.
      const { errors } = e.response.data;
      const errorMessage = Object.keys(errors)
        .map(error => errors[error])
        .join('\n');
      yield call(callback, null, errorMessage);
    } else {
      // handle server error (500)
      yield call(callback, null, e.message);
    }
  }
}

export function* loginCrossTabsSaga(
  action: PayloadAction<CrossTabsLoginPayload, any, SuccessErrorCallback>
) {
  yield put(loaderActions.startConnecting());

  const isChromeExtension = action.payload.isChromeExtension;
  const token = getCookie('optx_token') || action.payload.token;

  if (token) {
    yield call(setAxiosAuthorizationHeaders, token);
    yield call(JWTStorage.setJWT, token);
    yield put(push(isChromeExtension ? appRoutes.chromeExtension : appRoutes.home));
  }

  yield put(loaderActions.endConnecting());
}

export default function* profileSaga() {
  yield takeLatest(actions.userLogin, loginSaga);
  yield takeLatest(actions.userLoginCrossTabs, loginCrossTabsSaga);
}
