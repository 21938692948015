import React from 'react';
// models
import { TouchFieldProps } from './interface';
// components
import InputField from './InputField';
import InputBankerNameField from './InputBankerNameField';
import MultiInputField from './MultiInputField';
import MultiInputMillionsField from './MultiInputMillionsField';
import InputNumberField from './InputNumberField';
import InputLocationField from './InputLocationField';
import InputPhoneField from './InputPhoneField';
import InputMillionsField from './InputMillionsField';
import InputNumberPercentageField from './InputNumberPercentageField';
import SingleSelectField from './SingleSelectField';
import SingleSelectFieldContact from './SingleSelectFieldContact';
import SingleSelectSectorField from './SingleSelectSectorField';
import SingleSelectBankerField from './SingleSelectBankerField';
import SingleSelectRoundField from './SingleSelectRoundField';
import SingleSelectObjectField from './SingleSelectObjectField';
import SingleSelectWithRationaleField from './SingleSelectWithRationaleField';
import SingleSelectYearFoundedField from './SingleSelectYearFoundedField';
import TextareaField from './TextareaField';
import DatepickerField from './DatepickerField';
import MultiSelectField from './MultiSelectField';
import MultiSelectAllField from './MultiSelectAllField';
import MultiSelectAsyncFieldWrapper from './MultiSelectAsyncFieldWrapper';
import MultiSelectAsyncRequiredField from './MultiSelectAsyncRequiredField';
import MultiSelectAsyncCreatableFieldWrapper from './MultiSelectAsyncCreatableFieldWrapper';
import MultiSelectObjectField from './MultiSelectObjectField';
import AsyncCreatableSelectLastInvestors from './AsyncCreatableSelectLastInvestors';
import RadioGroupField from './RadioGroupField';
import MultiSelectPopupField from './MultiSelectPopupField';
import DateRangeField from './DateRangeField';

const FieldFactory: React.FC<TouchFieldProps> = ({ field, fieldUsedFor }) => {
  switch (field.type) {
    case 'input':
      return field.id === 'banker_name' ? (
        <InputBankerNameField field={field} fieldUsedFor={fieldUsedFor} />
      ) : (
        <InputField field={field} fieldUsedFor={fieldUsedFor} />
      );

    case 'multi-input':
      return field.id !== 'gm' ? (
        <MultiInputMillionsField field={field} fieldUsedFor={fieldUsedFor} />
      ) : (
        <MultiInputField field={field} fieldUsedFor={fieldUsedFor} />
      );

    case 'input-number':
      return field.id === 'gross_retention' || field.id === 'net_retention' ? (
        <InputNumberPercentageField field={field} fieldUsedFor={fieldUsedFor} />
      ) : field.id === 'capital_raised' ||
        field.id === 'equity_check' ||
        field.id === 'last_round_amount' ? (
        <InputMillionsField field={field} fieldUsedFor={fieldUsedFor} />
      ) : (
        <InputNumberField field={field} fieldUsedFor={fieldUsedFor} />
      );

    case 'textarea':
      return <TextareaField field={field} characterLimit={500} fieldUsedFor={fieldUsedFor} />;

    case 'input-location':
      return <InputLocationField field={field} />;

    case 'input-phone':
      return <InputPhoneField field={field} fieldUsedFor={fieldUsedFor} />;

    case 'multi-select':
      return field.id === 'customer_type' ? (
        <MultiSelectPopupField field={field} colSpan={8} hidePenIcon popupWidth={480} />
      ) : field.id === 'ops_team' || field.id === 'senior_deal_team_lead' ? (
        <MultiSelectAllField field={field} />
      ) : (
        <MultiSelectField field={field} fieldUsedFor={fieldUsedFor} />
      );

    case 'multi-select-object':
      return <MultiSelectObjectField field={field} />;

    case 'single-select-object':
      return field.id === 'last_round' ? (
        <SingleSelectRoundField field={field} fieldUsedFor={fieldUsedFor} />
      ) : (
        <SingleSelectObjectField field={field} fieldUsedFor={fieldUsedFor} />
      );

    case 'single-select':
      return field.id === 'sub_sector' ? (
        <SingleSelectSectorField field={field} fieldUsedFor={fieldUsedFor} />
      ) : field.id === 'banker' ? (
        <SingleSelectBankerField field={field} fieldUsedFor={fieldUsedFor} />
      ) : field.id === 'last_round' ? (
        <SingleSelectRoundField field={field} fieldUsedFor={fieldUsedFor} />
      ) : field.id === 'primary_contact' ? (
        <SingleSelectFieldContact field={field} fieldUsedFor={fieldUsedFor} />
      ) : field.id === 'year_founded' ? (
        <SingleSelectYearFoundedField field={field} fieldUsedFor={fieldUsedFor} />
      ) : field.id === 'stage' || field.id === 'is_software' || field.id === 'is_interesting' ? (
        <SingleSelectWithRationaleField field={field} />
      ) : (
        <SingleSelectField field={field} fieldUsedFor={fieldUsedFor} />
      );

    case 'datepicker':
      return <DatepickerField field={field} fieldUsedFor={fieldUsedFor} />;

    case 'date-range':
      return <DateRangeField field={field} />;

    case 'single-select-async':
      return <MultiSelectAsyncRequiredField field={field} fieldUsedFor={fieldUsedFor} />;

    case 'multi-select-async':
      return field.requiredFor ? (
        <MultiSelectAsyncRequiredField field={field} fieldUsedFor={fieldUsedFor} />
      ) : field.id === 'last_investors' ? (
        <AsyncCreatableSelectLastInvestors field={field} />
      ) : field.id === 'sub_vertical' ? (
        <MultiSelectAsyncCreatableFieldWrapper field={field} fieldUsedFor={fieldUsedFor} />
      ) : (
        <MultiSelectAsyncFieldWrapper field={field} fieldUsedFor={fieldUsedFor} />
      );

    case 'radio-group':
      return <RadioGroupField field={field} />;

    default:
      return null;
  }
};

export default FieldFactory;
