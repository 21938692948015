import { setAxiosAuthorizationHeaders } from '@optx/modules/axios';
import { getCookie } from '@optx/redux/login/utils';

/**
 * @description The storage item name
 * @type {string}
 */
const JWT = 'optx_token';

/**
 * @description get item from localStorage
 * @return {string | null}
 */
const getJWT = () => {
  return localStorage.getItem(JWT) || getCookie('optx_token');
};

/**
 * @description Set value to localStorage
 * @param {string} value item value
 */
const setJWT = (value: string) => {
  localStorage.setItem(JWT, value);
};

/**
 * @description remove jwt from localStorage
 */
const removeJWT = () => {
  localStorage.removeItem(JWT);
};

/**
 * @description Check is jwt token exist in localStorage
 * @return {Boolean}
 */
const exists = () => {
  const item = getJWT();

  return typeof item !== 'undefined' && item !== null;
};

// because tokens are being refreshed at certain time intervals,
// this is a fix for updating the token in cases where you have another
// optx tab open and you go back to it after a certain amount of time, preventing
// the user from being logged out
window.addEventListener('storage', (event: StorageEvent) => {
  if (event.key === JWT) {
    const { newValue } = event;

    if (newValue) {
      setJWT(newValue);
      setAxiosAuthorizationHeaders(newValue);
    }
  }
});

export default {
  getJWT,
  setJWT,
  removeJWT,
  exists,
};
