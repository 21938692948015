import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
// models
import { TouchType } from '@models/company/CompanyTouch';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// components
import TouchIcon from '@optx/features/company-touches/components/TouchIcon';

// this number is to show in tooltip only dates that are not displayed
const startDateListIndex = 1;

const StyledWrapper = styled.div`
  display: flex;
  align-tems: center;
  height: 26px;
  justify-content: space-between;
`;

interface MultipleDateProps {
  dates: string[];
  additionalParameter?: TouchType[];
  emptyValue?: string;
  format: string;
}

export const MultipleDate: React.FC<MultipleDateProps> = ({
  dates,
  additionalParameter,
  emptyValue,
  format,
}) => {
  if (!dates.length) {
    return <>{emptyValue || DEFAULT_EMPTY_VALUE}</>;
  }

  const shouldShowTooltip = dates.length > 1;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const normalizedDates = useMemo(() => {
    return dates.map((date: string) => moment(date).format(format));
  }, [dates, format]);

  const tooltipAdditionalContent =
    shouldShowTooltip && additionalParameter
      ? dates.slice(startDateListIndex).map((date: string, index: number) => {
          return (
            <StyledWrapper key={index}>
              <span>{moment(date).format(format)}</span>
              {additionalParameter.slice(startDateListIndex)[index] && (
                <span>
                  <TouchIcon
                    type={additionalParameter.slice(startDateListIndex)[index] as TouchType}
                    color="#fff"
                  />
                </span>
              )}
            </StyledWrapper>
          );
        })
      : null;

  const commonContent = dates.slice(startDateListIndex).map((date: string, index: number) => {
    return <div key={index}>{moment(date).format(format)}</div>;
  });

  const tooltipContent = tooltipAdditionalContent || commonContent;

  return shouldShowTooltip ? (
    <Tooltip
      overlayStyle={{ minWidth: `${additionalParameter ? 150 : 100}px` }}
      title={tooltipContent}
      className="text-truncate"
    >
      {normalizedDates[0]} <span>{`(${dates.length - startDateListIndex} more)`}</span>
    </Tooltip>
  ) : (
    <>{normalizedDates}</>
  );
};
