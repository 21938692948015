import moment from 'moment';
import { Card } from 'antd';
// models
import { TouchType } from '@models/company/CompanyTouch';
// constants
import globalConfig from '@optx/constants/config';
// utils
import { getDateColor } from '@utils/text';
// components
import { MultipleDate } from '@components/common/MultipleDate';

interface IndicatorProps {
  label: string;
  type?: TouchType | TouchType[];
  value: string | string[];
}

const Indicator = ({ label, type, value }: IndicatorProps) => {
  const isScheduled = label === 'Next Schedule Touch';

  return (
    <Card className={`relationship-summary ${isScheduled && 'relationship-summary--scheduled'}`}>
      <div className="d-flex align-items-center justify-content-between">
        {type ? (
          <div
            className="relationship-summary__value-date relationship-summary__value"
            style={{
              color: `${getDateColor(typeof value === 'string' ? value : value[0], isScheduled)}`,
            }}
          >
            {isScheduled ? (
              <MultipleDate
                dates={value as string[]}
                format={globalConfig.grid_date.DATE_FORMAT}
                additionalParameter={type as TouchType[]}
                emptyValue={'None'}
              />
            ) : (
              moment(value).format(globalConfig.grid_date.DATE_FORMAT)
            )}
          </div>
        ) : (
          <div className="relationship-summary__value">{value ? value : 'None'}</div>
        )}
      </div>
      <div className="relationship-summary__title">{label}</div>
    </Card>
  );
};

export default Indicator;
