import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import { CrossTabsLoginPayload, LoginPayload } from './interface';

export const userLogin = createAction(types.USER_LOGIN, (login?: LoginPayload, callback?: any) => ({
  payload: login,
  meta: callback,
}));

export const userLoginCrossTabs = createAction(
  types.USER_LOGIN_CROSSTABS,
  (payload: CrossTabsLoginPayload, callback?: any) => ({
    payload,
    meta: callback,
  })
);
