import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import classNames from 'classnames';
// styles
import './styles.scss';
// models
import { SelectOption } from '@optx/models/Option';
// constants
import { HTML } from '@constants/fullScreen';
// utils
import { mapTagsToNamesAndOperators } from '@utils/filters/mapTagsToNamesAndOperators';
// redux
import { actions as filterCardsActions } from '@optx/redux/my-companies/filters-cards';
import { actions as searchActions } from '@features/grid/search';
import {
  actions as fullScreenActions,
  selectors as fullscreenSelectors,
} from '@redux/ui/settings/fullscreen';
import { shouldFetchMyCompanies } from '@components/pages/MyCompanies/selectors';
// hooks
import useRefreshView from '@hooks/saved-searches/useRefreshView';
import {
  useInjectMyCompanies,
  useInjectCompanyFundingRounds,
  useInjectCompanyInBusinessEvaluation,
  useInjectCompanyActive,
  useInjectScore,
} from '@hooks/inject';
import { useInject as useInjectTouches } from '@features/company-touches';
import { useInject as useInjectCompanyCard } from '@features/long-card/company-card';
import { useReduxFullScreen } from '@hooks/fullscreen';
import {
  useInject as useInjectCompanyIndividualEdit,
  useInitialFetchEditOptions,
} from '@optx/components/feature/company-individual-edit';
import { useInject as useInjectScroll } from '@features/scroll-history';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { selectors as filterSelectors } from '@features/grid/filter';
import { useInject as useInjectCompanyGraphs } from '@features/company/graphs';
import {
  useInitFavorites,
  useFetchSavedSearches,
  useInitFilters,
  useFetchUserList,
} from '@optx/common/hooks/init';
import { useInjectCompanyReview } from '@optx/features/company-review';
import { selectors as searchesSelectors } from '@redux/company/saved-searches';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as userSelectors } from '@redux/user/information';
// components
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import { useInjectEditFields } from '@optx/features/company/edit-fields';
import FiltersModal from './FiltersModal';
import { MainContent, MainHeader } from '../../common/layout';
import PageLoader from './PageLoader';
import MyCompaniesSearch from './MyCompaniesSearch';
import SecondaryQuickFiltersContainer from './SecondaryQuickFiltersContainer';
import CompanyNotes from '../../CompanyNotes';
import CompanyTouches from '@features/add-touch/components';
import SecondaryHeader from './SecondaryHeader';
import DisplayColumnModal from './DisplayColumnModal';
import Cards from './Cards';
import Styled from './MyCompanies.styles';
import FilterTags from './FilterTags';
import CompanySearchDisplay from './CompanySearchDisplay';
import { MyCompaniesContext } from './MyCompaniesContext';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import { StickyHeader } from '@optx/components/common/StickyHeader.styled';
import EditAllDialog from '@optx/features/edit-all-info/components/EditAllDialog';
import DocumentUploadModal from '@optx/features/add-touch/components/DocumentUploadModal';
// eslint-disable-next-line max-len
import { default as CombinedSearchFilterTags } from '@optx/components/common/table/TableSavedSearches/cells/company/FilterTags';

const useInject = () => {
  useInjectEditFields();
  useInjectCompanyIndividualEdit();
  useInjectScroll();
  useInjectCompanyCard();
  useInjectCompanyFundingRounds();
  useInjectTouches();
  useInjectCompanyGraphs();
  useInjectMyCompanies();
  useInjectCompanyInBusinessEvaluation();
  useInjectCompanyActive();
  useInjectScore();
  useInjectCompanyReview();
};

const useInit = () => {
  const dispatch = useDispatch();
  useInitFilters();
  useInitFavorites();
  useFetchSavedSearches();

  const shouldFetch = useSelector(shouldFetchMyCompanies);

  useEffect(() => {
    if (shouldFetch) {
      dispatch(filterCardsActions.initializeMyCompaniesCards());
    }
  }, [dispatch, shouldFetch]);

  // individual edit
  useInitialFetchEditOptions();
  useFetchUserList(false);
};

const MyCompanies: React.FC = () => {
  useInject();
  useInit();
  useRefreshView('myCompanies');
  const [selectedData, setSelectedData] = useState<SelectOption[] | string | boolean>([]);
  const [rationaleValue, setRationaleValue] = useState<string>();
  const [hasSelectViewChanged, setHasSelectViewChanged] = useState<boolean>(false);
  const [panelHeight, setPanelHeight] = useState<number>(0);

  const savedSearches = useSelector(searchesSelectors.getSavedSearches);
  const combinedSearchId = useSelector(userSelectors.getCombinedSearchId('myCompanies'));
  const selectedCombinedSearch = savedSearches.find(
    search => search.unique_id === combinedSearchId
  );
  const listType = useSelector(searchSelectors.getListType('myCompanies'));

  const filterNames = mapTagsToNamesAndOperators(
    selectedCombinedSearch?.parent_search_ids,
    savedSearches,
    selectedCombinedSearch?.logical_operators
  );

  const dispatch = useDispatch();

  // fullscreen functionality
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const showClearButton = useSelector(filterSelectors.showCustomClearFilter('myCompanies'));
  const { isFullscreen } = useReduxFullScreen({ dom: HTML, fullscreen, toggle });
  const contentClassNames = classNames('main-content grid-page has-search', {
    fullscreen: isFullscreen,
  });

  // long card
  const viewType = useSelector(viewTypeSelectors.searchView('myCompanies'));
  const longCardView = viewType === 'long-card';

  const handleHeightChange = useCallback(
    (height: number) => {
      setPanelHeight(height);
    },
    [setPanelHeight]
  );

  return (
    <MyCompaniesContext.Provider
      value={{
        selectedData,
        setSelectedData,
        gridName: 'myCompanies',
        hasSelectViewChanged,
        setHasSelectViewChanged,
        rationaleValue,
        setRationaleValue,
      }}
    >
      <div className="my-companies">
        <PageLoader />
        <MainHeader className="main-header fixed">
          <div className="search-header-content">
            <MyCompaniesSearch className="antd-search-input" autoComplete="off" />
            {listType === 'combined' ? (
              <CombinedSearchFilterTags
                filterNames={filterNames}
                listType={listType}
                savedSearches={filterNames}
              />
            ) : (
              <FilterTags />
            )}
            <Space size={4} style={{ marginLeft: 'auto' }}>
              <ClearSearchButton
                title="Clear Search"
                isVisible={showClearButton || listType === 'combined'}
                onClick={() =>
                  dispatch(searchActions.clearSearch({ gridKey: 'myCompanies', data: true }))
                }
              />
            </Space>
            {isFullscreen && <GridFullScreen />}
          </div>
        </MainHeader>
        <StickyHeader offsetTop="64px">
          <SecondaryQuickFiltersContainer onHeightChange={handleHeightChange} />
        </StickyHeader>
        <MainContent className={contentClassNames}>
          <Styled.PageTitle className={fullscreen ? 'is-fullscreen' : ''}>
            My Companies <span>(Manage My Pipeline)</span>
          </Styled.PageTitle>
          <Cards />
          <StickyHeader isFullScreen={fullscreen} dynamicOffsetTop={panelHeight} isMyCompanies>
            <SecondaryHeader data-virtual-scroll-substract="my-companies-grid" />
          </StickyHeader>
          <CompanySearchDisplay isLongCardView={longCardView} />
          <CompanyNotes />
          <CompanyTouches />
          <EditAllDialog />
          {!longCardView && <DisplayColumnModal />}
          <FiltersModal />
        </MainContent>
      </div>
      <DocumentUploadModal />
    </MyCompaniesContext.Provider>
  );
};

export default MyCompanies;
