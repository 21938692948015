import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// utils
import { iframePostMessage } from '@optx/utils/window';
import extensionStorage from '@optx/storage/localStorage/extensionStorage';
// models
import { FoundCompany } from '@optx/models/Company';
// redux
import { useInject as useInjectCompanyCard } from '@features/long-card/company-card';
import {
  actions as extensionActions,
  selectors as extensionSelectors,
} from '@redux/company/extension-profile';
// hooks
import { useInject as useInjectTouches } from '@features/company-touches';
import { useInject as useInjectCompanyGraphs } from '@features/company/graphs';
import {
  useInjectCompanyLists,
  useInjectExtensionProfile,
  useInjectOpportunityPresentation,
  useInjectCompanyRelationshipManagement,
} from '@hooks/inject';
import { useInject as useInjectCompanyIndividualEdit } from '@components/feature/company-individual-edit';
// local
import { Context } from './ChromeExtensionContext';
import Styled from './components/styled/ChromeExtension.styled';
import ExtensionProfile from './components/ExtensionProfile';
import SkeletonLoader from './components/SkeletonLoader';
import CompanyTouches from '@optx/features/add-touch/components';
import EditAllDialog from '@optx/features/edit-all-info/components/EditAllDialog';
import DocumentUploadModal from '@optx/features/add-touch/components/DocumentUploadModal';

const ChromeExtension = () => {
  useInjectExtensionProfile();
  useInjectCompanyCard();
  useInjectTouches();
  useInjectCompanyLists();
  useInjectCompanyGraphs();
  useInjectCompanyIndividualEdit();
  useInjectOpportunityPresentation();
  useInjectCompanyRelationshipManagement();

  const extensionDisplay: boolean = extensionStorage.isDisplay() !== 'false';

  const dispatch = useDispatch();

  const getProfiles = useSelector(extensionSelectors.getProfiles);

  const [companiesLoading, setCompaniesLoading] = useState(true);
  const [foundCompanies, setFoundCompanies] = useState<Array<FoundCompany>>([]);

  useEffect(() => {
    document.body.classList.add('chrome-extension-body');

    // change layout background color
    (document.getElementsByClassName('right-section')[0] as HTMLElement).style.backgroundColor =
      '#f5f7fa';

    const hostURL = window.name;
    iframePostMessage('icon-status', Boolean(false));

    iframePostMessage('hide-optx-extension', Boolean(extensionDisplay));

    if (hostURL !== '') {
      dispatch(extensionActions.fetchExtensionCompanies(hostURL, () => setCompaniesLoading(false)));
    }

    return () => {
      dispatch(extensionActions.clearExtensionCompanies());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getProfiles && getProfiles.length > 0) {
      setFoundCompanies(getProfiles);
      iframePostMessage('icon-status', Boolean(true));
    }
  }, [getProfiles]);

  return (
    <Context.Provider
      value={{
        companiesLoading,
        setCompaniesLoading,
        foundCompanies,
        setFoundCompanies,
      }}
    >
      <Styled.ChromeExtension className="chrome-extension">
        {companiesLoading && <SkeletonLoader />}
        <ExtensionProfile />
        <CompanyTouches isChromeExtension={true} />
        <EditAllDialog isChromeExtension={true} />
        <DocumentUploadModal isChromeExtension />
      </Styled.ChromeExtension>
    </Context.Provider>
  );
};

export default ChromeExtension;
